import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { LogoIcon } from 'application/assets';

import { LoaderStyled } from './styles';

interface LoaderProps {
  text?: string;
}

export const Loader: FC<LoaderProps> = ({ text }) => {
  const { t } = useTranslation();
  return (
    <LoaderStyled>
      <div>
        <LogoIcon />
      </div>
      <h1>
        {t('global.loader')}
        <br />
        {text}
      </h1>
    </LoaderStyled>
  );
};
