import {
  UserLoginResDto,
  UserLoginDto,
  UserRefreshTokenDto,
  UserSetPasswordDto,
  UserRefreshTokenResDto,
  UserVerifyCodeDto,
} from '@docbay/schemas';
import axiosInstance from './axiosInstance';

import { ForgotPasswordDto } from 'common/auth/signIn/models';
import { getLanguageFromLocalStorage } from 'application/helpers/language';
import axiosNonTokenInstance from './axiosNonTokenInstance';

export const UsersAPI = {
  usersLogin: (data: UserLoginDto) =>
    axiosNonTokenInstance.post<UserLoginResDto>('/users/login', data),
  usersRefreshToken: (data: UserRefreshTokenDto) =>
    axiosNonTokenInstance.post<UserRefreshTokenResDto>(
      '/users/refresh-token',
      data,
    ),
  usersForgotPassword: (data: ForgotPasswordDto) => {
    const { captchaToken, ...restData } = data;
    return axiosNonTokenInstance.post('/users/forgot-password', restData, {
      headers: {
        recaptcha: captchaToken,
      },
    });
  },
  usersVerifyCode: (data: UserVerifyCodeDto) => {
    const language = getLanguageFromLocalStorage();
    return axiosNonTokenInstance.post('/users/verify-code', {
      ...data,
      language,
    });
  },
  usersCreateNewPassword: (data: UserSetPasswordDto) =>
    axiosNonTokenInstance.post('/users/password', data),
  signOut: () => axiosInstance.post('/users/logout'),
  setUserLanguage: (languageIso: string) =>
    axiosInstance.post('/users/change-language', { languageIso }),
  fetchUserProfile: () => axiosInstance.get('/hr-department-user/profile'),
};
