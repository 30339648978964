import { HrDepartmentLocationCreationRequestDto } from '@docbay/schemas';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { RequestEnum } from 'application/store/reducers/request/model';
import { GetParams, LocationUpdatePrams } from 'application/types/locations';

import { LocationsAPI } from 'integration/api/locations';

const getLocations = createAsyncThunk(
  RequestEnum.getLocations,
  async (params: GetParams, { rejectWithValue }) => {
    try {
      const { data } = await LocationsAPI.getLocations(params);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

const getLocationById = createAsyncThunk(
  RequestEnum.getLocationById,
  async (locationId: string, { rejectWithValue }) => {
    try {
      const { data } = await LocationsAPI.getLocationById(locationId);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

const addLocation = createAsyncThunk(
  RequestEnum.addLocation,
  async (
    params: HrDepartmentLocationCreationRequestDto,
    { rejectWithValue },
  ) => {
    try {
      const { data } = await LocationsAPI.addLocation(params);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

const updateLocation = createAsyncThunk(
  RequestEnum.updateLocation,
  async (params: LocationUpdatePrams, { rejectWithValue }) => {
    try {
      const { data } = await LocationsAPI.updateLocation(params);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);
const deleteLocation = createAsyncThunk(
  RequestEnum.deleteLocation,
  async (locationId: string, { rejectWithValue }) => {
    try {
      const { data } = await LocationsAPI.deleteLocation(locationId);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

export const LocationsActions = {
  getLocations,
  getLocationById,
  addLocation,
  updateLocation,
  deleteLocation,
};
