import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { SupportedLanguageIso } from '@docbay/schemas';
import dayjs from 'dayjs';

import {
  Input,
  PrimaryButton,
  SecondaryButton,
  SuccessModal,
} from 'application/components';
import { CreatePassword } from 'application/components';
import { requestSelector } from 'application/store/reducers/request/selectors';
import { useAppDispatch, useAppSelector } from 'application/hooks/redux';
import {
  CompanyDataSessionStorage,
  clearCompanyDataFromSessionStorage,
  getCompanyDataFromSessionStorage,
  setCompanyDataToSessionStorage,
  setFocusOnEmailSessionStorage,
} from 'application/helpers/sessionStorage/companyRegistration';
import { CompanyRegistrationActions } from 'application/store/reducers/CompanyRegistration/actions';
import { getLanguageFromLocalStorage } from 'application/helpers/language';
import { isResponseFulfilled } from 'application/helpers/responseHelper';
import { useNavigate } from 'react-router-dom';
import { PathNames } from 'application/routes/constants';
import { clearCompanyData } from 'application/store/reducers/CompanyRegistration/companyRegistrationSlice';

import { Loader } from 'common';

import { useSetPasswordSchema } from 'features/company-registration/hooks/useSetPasswordSchema';

import ButtonGroup from '../ButtonGroup';
import Timer from '../Timer';
import BackModal from '../BackModal';

import { FormData, SetPasswordProps } from './models';
import {
  StyledForm,
  ButtonSectionStyled,
  DividerStyled,
  DividerVertical,
} from './styles';

const SetPassword: FC<SetPasswordProps> = ({ setStep }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { adminData } = useAppSelector((state) => state.companyRegistration);
  const { companyConfirmSingUp } = useAppSelector(requestSelector);
  const [timeChanged, setTimeChanged] = useState(0);
  const [isCodeExpired, setIsCodeExpired] = useState<boolean>(false);
  const [showBackModal, setShowBackModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const isLoading = companyConfirmSingUp.loading;

  const setPasswordSchema = useSetPasswordSchema();

  const {
    register,
    handleSubmit,
    watch,
    getValues,
    setError,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver(setPasswordSchema),
    mode: 'onChange',
  });

  useEffect(() => {
    const timerEndDate = getCompanyDataFromSessionStorage(
      CompanyDataSessionStorage.TIMER_END_DATE,
    );
    const codeExpired = dayjs().isAfter(dayjs(timerEndDate || ''));

    setIsCodeExpired(!!codeExpired);
  }, []);

  const onChangeEmail = () => {
    setFocusOnEmailSessionStorage(true);
    setStep(1);
    setCompanyDataToSessionStorage(CompanyDataSessionStorage.STEP, 1);
  };

  const handleResendCode = async () => {
    const response = await dispatch(
      CompanyRegistrationActions.resendConfirmationCode({
        language: getLanguageFromLocalStorage() as SupportedLanguageIso,
        email: adminData.email,
      }),
    );

    isResponseFulfilled(response, () => {
      const timerCountDownInMinutes = 5;
      setCompanyDataToSessionStorage(
        CompanyDataSessionStorage.TIMER_END_DATE,
        dayjs().set('minute', dayjs().get('minute') + timerCountDownInMinutes),
      );
      setIsCodeExpired(false);
      setTimeChanged(dayjs().get('minute') + timerCountDownInMinutes);
    });
  };

  const goToCompanyInformation = () => {
    setStep(2);
    setCompanyDataToSessionStorage(CompanyDataSessionStorage.STEP, 2);
  };

  const onBack = () => {
    const notAskAnymore = getCompanyDataFromSessionStorage(
      CompanyDataSessionStorage.NOT_ASK_ANYMORE,
    );
    if (notAskAnymore) {
      goToCompanyInformation();
    } else {
      setShowBackModal(true);
    }
    setCompanyDataToSessionStorage(
      CompanyDataSessionStorage.USER_EMAIL,
      adminData.email,
    );
  };

  const onSubmit: SubmitHandler<FormData> = async (data) => {
    const { code, password } = data;
    const response = await dispatch(
      CompanyRegistrationActions.companyConfirmSingUp({
        email: adminData.email,
        code: String(code),
        password,
      }),
    );
    isResponseFulfilled(
      response,
      () => {
        setShowSuccessModal(true);
        clearCompanyDataFromSessionStorage();
        dispatch(clearCompanyData());
      },
      () => {
        setError('code', { message: t('errors.incorrect_code') });
      },
    );
  };

  const onCloseSuccessModal = () => {
    setShowSuccessModal(false);
    navigate(PathNames.login);
  };

  const disabledButton = !!Object.keys(errors).length;

  return (
    <>
      {isLoading && <Loader />}
      <StyledForm onSubmit={handleSubmit(onSubmit)}>
        <div>
          <Timer
            timeChanged={timeChanged}
            isCodeExpired={isCodeExpired}
            setIsCodeExpired={setIsCodeExpired}
          />
          <Input
            id="code"
            label={
              t('company_registration.set_password.verification_code') || ''
            }
            type="number"
            placeholder={
              t(
                'company_registration.set_password.verification_code_placeholder',
              ) || ''
            }
            errorMessage={
              (errors.code?.message && t('errors.incorrect_code')) || ''
            }
            register={register}
            disabled={isCodeExpired}
          />
          <ButtonSectionStyled>
            {isCodeExpired ? (
              <h2>{t('company_registration.set_password.code_expired')}</h2>
            ) : (
              <p>{t('company_registration.set_password.still_no_code')}</p>
            )}
            <div>
              <button type="button" onClick={onChangeEmail}>
                {t('company_registration.set_password.use_other_email')}
              </button>
              <DividerVertical />
              <button type="button" onClick={() => handleResendCode()}>
                {t('company_registration.set_password.resend_code')}
              </button>
            </div>
          </ButtonSectionStyled>
          <DividerStyled />
          <CreatePassword
            watch={watch}
            getValues={getValues}
            register={register}
            errors={errors.password?.message}
          />
        </div>
        <ButtonGroup>
          <PrimaryButton disabled={disabledButton} type="submit">
            {t('global.continue')}
          </PrimaryButton>
          <SecondaryButton onClick={onBack} disabled={false} type="button">
            {t('global.back')}
          </SecondaryButton>
        </ButtonGroup>
      </StyledForm>
      {showBackModal && (
        <BackModal
          opened={showBackModal}
          onClose={() => setShowBackModal(false)}
          onSubmit={goToCompanyInformation}
        />
      )}
      {showSuccessModal && (
        <SuccessModal
          opened={showSuccessModal}
          onClose={onCloseSuccessModal}
          firstText={t('company_registration.company_registration_success')}
        />
      )}
    </>
  );
};

export default SetPassword;
