import styled from '@emotion/styled';

import { commonColors } from 'application/theme';

interface StepStyledProps {
  completed?: boolean;
  active?: boolean;
}

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const StepStyled = styled.div<StepStyledProps>`
  display: flex;
  flex-direction: column;
  gap: 8px;

  > div {
    display: flex;
    align-items: center;
    gap: 10px;

    div {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      border: ${({ active }) =>
        active ? 'none' : `1px solid ${commonColors.grey400}`};
      background-color: ${({ active, completed }) =>
        active
          ? commonColors.blue300
          : completed
          ? commonColors.white
          : 'transparent'};
      color: ${({ active }) =>
        active ? commonColors.grey900 : commonColors.grey400};
      font-weight: ${({ active }) => (active ? 700 : 500)};
      > svg {
        path {
          fill: ${commonColors.grey900};
        }
      }
    }

    p {
      color: ${({ active, completed }) =>
        active || completed ? commonColors.white : commonColors.grey400};
      font-size: 18px;
      font-style: normal;
      font-weight: ${({ active }) => (active ? 700 : 500)};
      line-height: 140%;
      letter-spacing: 0.09px;
    }
  }

  > svg {
    width: 32px;
    height: 12px;
    rect {
      fill: ${commonColors.grey600};
    }
  }
`;

export const StepIconRoot = styled.div<StepStyledProps>`
  z-index: 1;
  width: 32;
  height: 32;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  border: ${({ active }) =>
    active ? 'none' : `1px solid ${commonColors.grey300}`};
  background-color: ${({ active }) =>
    active ? commonColors.blue300 : 'transparent'};
  display: flex;
  font-size: 16px;
  color: ${commonColors.grey300};
  font-weight: ${({ active }) => (active ? 700 : 500)};
  color: ${({ active }) =>
    active ? commonColors.grey900 : commonColors.grey400};
`;
