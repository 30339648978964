import {
  CommonEntitiesListResponse,
  HrDepartmentEmployeeCreationRequestDto,
  HrDepartmentEmployeeResponseDto,
  HrDepartmentEmployeeUpdateDto,
} from '@docbay/schemas';

import axiosInstance from './axiosInstance';

interface Props {
  page?: number;
  limit?: number;
  languageIso?: string;
  departmentIds?: string[];
  search?: string;
}

export const EmployeesManagementAPI = {
  createEmployee: (data: HrDepartmentEmployeeCreationRequestDto) =>
    axiosInstance.post<HrDepartmentEmployeeResponseDto>('/employees', data),
  fetchEmployees: (params: Props) =>
    axiosInstance.get<
      CommonEntitiesListResponse<HrDepartmentEmployeeResponseDto>
    >('/employees', { params }),
  editEmployee: (params: {
    data: HrDepartmentEmployeeUpdateDto;
    employeeId: string;
  }) =>
    axiosInstance.patch<HrDepartmentEmployeeResponseDto>(
      `/employees/${params.employeeId}`,
      params.data,
    ),
  deleteEmployee: (employeeId: string) =>
    axiosInstance.delete(`/employees/${employeeId}`),
};
