import styled from '@emotion/styled';
import { commonColors } from 'application/theme';

export const Wrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  outline: none;
  display: flex;
  min-width: 500px;
  padding: 32px;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  border-radius: 8px;
  background: ${commonColors.blue100};
  box-shadow: 0 10px 45px 0 rgba(14, 40, 83, 0.07);

  > svg {
    position: absolute;
    width: 20px;
    height: 20px;
    right: 12px;
    top: 12px;
    cursor: pointer;

    path {
      fill: ${commonColors.grey500};
    }
  }
    
  > div {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
      
      p {
          font-family: 'Mulish', sans-serif;
          font-size: 18px;
          
          span {
              font-weight: 700;
          }
      }
  }  
    
    button {
        width: 120px;
        background: ${commonColors.white};
    }
`;
