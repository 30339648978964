import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { PickIcon } from 'application/assets';

import { NoDataElementProps } from './models';
import { StyledTr } from './styles';
import { featuresPermissions } from 'application/helpers/featuresPermissions';

const EmptyState: FC<NoDataElementProps> = ({
  handleShowModal,
  withSearch,
}) => {
  const { t } = useTranslation();
  const { hasLocationManagementPermission } = featuresPermissions();

  return (
    <StyledTr>
      <td colSpan={3}>
        <div>
          <PickIcon />
          {withSearch ? (
            <p> {t('locations.no_data.no_user')}</p>
          ) : (
            <p>
              {t('locations.no_data.title')}
              {hasLocationManagementPermission && (
                <>
                  <br />
                  {t('locations.no_data.text_1')}{' '}
                  <button type="button" onClick={handleShowModal}>
                    {t('locations.no_data.text_2')}
                  </button>
                </>
              )}
            </p>
          )}
        </div>
      </td>
    </StyledTr>
  );
};
export default EmptyState;
