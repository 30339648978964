import React, { FC, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { SupportedLanguageIso } from '@docbay/schemas';

import { Input, PrimaryButton, InputPhone } from 'application/components';
import {
  CompanyDataSessionStorage,
  deleteCompanyDataFromSessionStorage,
  focusOnEmailInput,
  getCompanyDataFromSessionStorage,
  setCompanyDataToSessionStorage,
  setFocusOnEmailSessionStorage,
} from 'application/helpers/sessionStorage/companyRegistration';
import { useAppDispatch, useAppSelector } from 'application/hooks/redux';
import { setAdminData } from 'application/store/reducers/CompanyRegistration/companyRegistrationSlice';
import { getLanguageFromLocalStorage } from 'application/helpers/language';

import { usePersonalInfoSchema } from 'features/company-registration/hooks/usePersonalInfoScheme';

import ButtonGroup from '../ButtonGroup';

import { FormData, PersonalInformationProps } from './models';
import { StyledForm } from './styles';

const PersonalInformation: FC<PersonalInformationProps> = ({ setStep }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { adminData } = useAppSelector((state) => state.companyRegistration);

  const personalInfoSchema = usePersonalInfoSchema();

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    setError,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver(personalInfoSchema),
    mode: 'onChange',
  });

  const errorHandler = () => {
    const hasEmailError = getCompanyDataFromSessionStorage(
      CompanyDataSessionStorage.HAS_EMAIL_ERROR,
    );
    const hasPhoneError = getCompanyDataFromSessionStorage(
      CompanyDataSessionStorage.HAS_PHONE_ERROR,
    );

    hasEmailError &&
      setError('email', {
        message: t('errors.cannot_register_user_email_error'),
      });
    hasPhoneError &&
      setError('phone', {
        message: t('errors.cannot_register_user_phone_error'),
      });

    deleteCompanyDataFromSessionStorage([
      CompanyDataSessionStorage.HAS_EMAIL_ERROR,
      CompanyDataSessionStorage.EMAIL_OLD,
      CompanyDataSessionStorage.HAS_PHONE_ERROR,
      CompanyDataSessionStorage.PHONE_OLD,
    ]);
  };

  useEffect(() => {
    setValue('firstName', adminData.firstName);
    setValue('lastName', adminData.lastName);
    setValue('jobPosition', adminData.jobPosition || '');
    setValue('email', adminData.email || '');
    adminData.phone && setValue('phone', Number(adminData.phone));
    errorHandler();
  }, [adminData]);

  const onSubmit: SubmitHandler<FormData> = async (data) => {
    dispatch(
      setAdminData({
        ...data,
        phone: String(data.phone),
        language: getLanguageFromLocalStorage() as SupportedLanguageIso,
        contractualAgreement: true,
      }),
    );
    setFocusOnEmailSessionStorage(false);
    setCompanyDataToSessionStorage(CompanyDataSessionStorage.STEP, 2);
    setCompanyDataToSessionStorage(
      CompanyDataSessionStorage.PERSONAL_INFORMATION,
      data,
    );
    setStep(2);
  };

  const disabledButton = !!Object.keys(errors).length;

  return (
    <>
      <StyledForm onSubmit={handleSubmit(onSubmit)}>
        <div>
          <Input
            id="firstName"
            label={t('global.first_name') || ''}
            type="text"
            placeholder={t('global.first_name_placeholder') || ''}
            errorMessage={errors.firstName?.message}
            register={register}
          />
          <Input
            id="lastName"
            label={t('global.last_name') || ''}
            type="text"
            placeholder={t('global.last_name_placeholder') || ''}
            errorMessage={errors.lastName?.message}
            register={register}
          />
          <Input
            id="jobPosition"
            label={t('global.job_position') || ''}
            type="text"
            placeholder={t('global.job_position_placeholder') || ''}
            errorMessage={errors.jobPosition?.message}
            register={register}
          />
          <Input
            id="email"
            label={t('global.email') || ''}
            type="email"
            placeholder={t('global.email_placeholder') || ''}
            register={register}
            errorMessage={errors.email?.message}
            isFocused={focusOnEmailInput()}
          />
          <InputPhone
            id="phone"
            label={t('global.phone') || ''}
            register={register}
            errorMessage={errors.phone?.message}
            value={watch('phone')}
            isValid={true}
          />
        </div>
        <ButtonGroup>
          <PrimaryButton disabled={disabledButton} type="submit">
            {t('global.continue')}
          </PrimaryButton>
        </ButtonGroup>
      </StyledForm>
    </>
  );
};
export default PersonalInformation;
