import React, { FC, useRef, useState } from 'react';

import {
  ArrowDropUpIcon,
  EnFlagIcon,
  FrFlagIcon,
  PtFlagIcon,
} from 'application/assets';
import useOnClickOutside from 'application/hooks/useOnClickOutside';
import { Languages, languagesList } from 'application/constants/languages';

import { LanguageDropdownProps } from './models';
import { DropdownStyled, OptionsList, OptionItem } from './styles';

const LanguageDropdown: FC<LanguageDropdownProps> = ({ value, onChange }) => {
  const ref = useRef(null);
  const [isActive, setIsActive] = useState(false);

  useOnClickOutside(ref, () => setIsActive(false));

  const getFlag = (language: Languages) => {
    switch (language) {
      case Languages.EN:
        return <EnFlagIcon />;
      case Languages.FR:
        return <FrFlagIcon />;
      default:
        return <PtFlagIcon />;
    }
  };

  const onSelect = (item: Languages) => {
    onChange(item);
    setIsActive(false);
  };

  return (
    <DropdownStyled isActive={isActive} ref={ref}>
      <section onMouseDown={() => setIsActive((prev) => !prev)}>
        <div>{getFlag(value)}</div>
        <ArrowDropUpIcon />
      </section>
      {isActive && (
        <OptionsList className="language-option-list">
          {languagesList.map((item) => (
            <OptionItem
              key={item}
              onClick={() => onSelect(item)}
              selected={value === String(item)}
            >
              {getFlag(item)}
              <p>{item}</p>
            </OptionItem>
          ))}
        </OptionsList>
      )}
    </DropdownStyled>
  );
};

export default LanguageDropdown;
