import styled from '@emotion/styled';
import { commonColors } from 'application/theme';

interface RuleStyledProps {
  isValid: boolean;
}

export const RulesSectionStyled = styled.div`
  display: grid;
  gap: 4px;
  margin-top: 4px;
`;

export const RuleStyled = styled.div<RuleStyledProps>`
  display: flex;
  align-items: center;
  font-family: 'Mulish', sans-serif;

  > span {
    font-size: 14px;
    line-height: 140%;
    color: ${({ isValid }) =>
      isValid ? commonColors.success1 : commonColors.grey600};
  }

  > svg {
    width: 16px;
    height: 16px;
    margin-right: 8px;
    path {
      fill: ${({ isValid }) =>
        isValid ? commonColors.success1 : commonColors.grey600};
    }
  }
`;
