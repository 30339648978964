import styled from '@emotion/styled';
import { commonColors } from 'application/theme';

interface CheckboxError {
  error: number;
}

export const Container = styled.div`
  width: 464px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 32px;

  a {
    text-decoration: none;
    color: ${commonColors.blue800};
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    letter-spacing: 0.08px;
  }
`;

export const TextSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  h1 {
    color: ${commonColors.grey900};
    text-align: center;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    letter-spacing: 0.28px;
    text-transform: capitalize;
  }

  h2 {
    color: ${commonColors.grey700};
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    letter-spacing: 0.09px;
  }
`;

export const FormStyled = styled.form`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;

  > button {
    margin-top: 16px;
  }

  div[type='email']:first-of-type {
    margin-bottom: 8px;
  }
`;

export const RowStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const FooterStyled = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 16px;
  width: 100%;

  > div:first-of-type {
    display: flex;
    align-items: center;
    width: 100%;

    p {
      padding: 0 12px;
      color: ${commonColors.grey800};
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
      letter-spacing: 0.07px;
    }
  }

  > div:last-of-type {
    display: flex;
    align-items: center;
    justify-content: center;

    p {
      margin-right: 8px;
      color: ${commonColors.grey800};
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%;
      letter-spacing: 0.08px;
    }
  }
`;

export const ReCaptchaStyled = styled('div')<CheckboxError>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.005em;
    color: ${({ error }) => (error ? commonColors.error2 : '')};
  }
`;

export const StyledError = styled.p`
  color: ${commonColors.error1};
  line-height: 19.6px;
  text-align: center;
  margin-top: 28px;
`;
