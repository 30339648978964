export const x_app_type = 'x-app-type';

export enum ApplicationType {
  ProfessionalWeb = 'ProfessionalWeb',
  ProfessionalMobile = 'ProfessionalMobile',
  PatientWeb = 'PatientWeb',
  PatientMobile = 'PatientMobile',
  AdminWeb = 'AdminWeb',
  HrDepartmentWeb = 'HrDepartmentWeb',
}
