import React, { FC } from 'react';

import { CheckboxProps } from './models';
import { StyledCheckbox } from './styles';

const Checkbox: FC<CheckboxProps> = ({
  label,
  id,
  checked,
  onChange,
  disabled,
}) => {
  return (
    <StyledCheckbox>
      <input
        type="checkbox"
        id={id}
        checked={checked}
        onChange={(e) => onChange(e.target.checked)}
        disabled={!!disabled}
      />
      <label htmlFor={id}>{label}</label>
    </StyledCheckbox>
  );
};

export default Checkbox;
