import styled from '@emotion/styled';
import { commonColors } from 'application/theme';

export const AccordionSettingsStyled = styled.div`
  margin-top: 20px;

  .accordion-root {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    height: 44px;
    width: 100%;
    padding: 10px 12px;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0.08px;
    border-radius: 4px;

    :hover {
      background: ${commonColors.navigatorHover};
    }
    &.active {
      border-radius: 4px;
      background: ${commonColors.green100} !important;
      p {
        color: ${commonColors.menu} !important;
      }
      svg {
        path {
          fill: ${commonColors.menu} !important;
        }
      }
    }
  }
  .accordion-title {
    display: flex;
    align-items: center;
    overflow: hidden;
    color: ${commonColors.white};
    > svg {
      position: absolute;
      margin-left: -2px;
      width: 24px;
      height: 24px;
    }

    > p {
      margin-left: 31px;
      position: relative;
      overflow: hidden;
    }
  }
  .accordion-expand {
    > svg {
      visibility: hidden;
      opacity: 0;
      path {
        fill: ${commonColors.white};
      }
    }
  }
  .accordion-list {
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;
    > div {
      button {
        display: flex;
        align-items: center;
        gap: 8px;
        position: relative;
        overflow: hidden;
        white-space: nowrap;
        text-decoration: none;
        height: 36px;
        padding: 8px;
        color: ${commonColors.grey700};
        font-size: 14px;
        font-weight: 500;
        line-height: 19.6px;
        letter-spacing: 0.08px;
        background: transparent;
        border-radius: 4px;
        border: none;
        text-align: start;
        cursor: pointer;
        :hover {
          background: ${commonColors.blue100};
        }
      }
    }
    .active {
      border-radius: 4px;
      background: ${commonColors.blue200};
      color: ${commonColors.grey900};
      font-weight: 700;
    }
  }
`;
