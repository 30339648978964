import styled from '@emotion/styled';
import { commonColors } from 'application/theme';

export const StyledForm = styled.form`
  height: inherit;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  > div:first-of-type {
    display: grid;
    grid-template-columns: 100%;
    gap: 28px;
    max-width: 470px;
  }
`;

export const ButtonSectionStyled = styled.div`
  > h2 {
    color: ${commonColors.error1};
    font-size: 16px;
    font-weight: 500;
    line-height: 22.4px;
  }

  > div {
    display: flex;
    align-items: center;
    margin-top: 6px;

    > p {
      font-size: 16px;
      font-weight: 500;
      line-height: 140%;
      color: ${commonColors.grey700};
    }

    > hr {
      border-color: ${commonColors.grey400};
      height: 17px;
      margin: 0 12px;
    }

    > button {
      border: none;
      background: none;
      padding: 2px;
      color: ${commonColors.blue800};
      font-size: 16px;
      font-weight: 700;
      line-height: 140%;
      letter-spacing: 0.08px;
      cursor: pointer;
    }
  }
`;

export const DividerStyled = styled.div`
  height: 1px;
  width: 100%;
  border-top: 1px solid ${commonColors.grey200};
`;

export const DividerVertical = styled.div`
  width: 1px;
  height: 11px;
  background-color: ${commonColors.grey400};
  margin: 0 12px;
`;
