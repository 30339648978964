import React, { FC, FormEvent } from 'react';
import { Modal as ModalMui } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { CloseIcon } from 'application/assets';

import { Loader } from 'common';

import { PrimaryButton, SecondaryButton } from '../index';

import { ModalProps } from './models';
import { Wrapper, HeaderStyled, ChildrenStyled, ButtonWrapper } from './styles';

const ManageTableModal: FC<ModalProps> = ({
  opened,
  onClose,
  title,
  onSubmit,
  disabledSubmit,
  children,
  isLoading,
}) => {
  const { t } = useTranslation();

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onSubmit(event);
  };

  return (
    <ModalMui open={opened}>
      <>
        {isLoading && <Loader />}
        <Wrapper onSubmit={handleSubmit}>
          <HeaderStyled>
            <h1>{title}</h1>
            <button type="button" onClick={onClose}>
              <CloseIcon />
            </button>
          </HeaderStyled>
          <ChildrenStyled>{children}</ChildrenStyled>
          <ButtonWrapper>
            <SecondaryButton type={'button'} onClick={onClose}>
              {t('global.cancel')}
            </SecondaryButton>
            <PrimaryButton
              type={'submit'}
              disabled={disabledSubmit}
              onClick={onSubmit}
            >
              {t('global.save')}
            </PrimaryButton>
          </ButtonWrapper>
        </Wrapper>
      </>
    </ModalMui>
  );
};

export default ManageTableModal;
