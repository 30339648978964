export type RequestState = {
  [name in RequestEnum]: RequestData;
};

export interface RequestData {
  loading: boolean;
  error: string;
}

export enum RequestEnum {
  //company registration
  companySignUp = 'companySignUp',
  companyConfirmSingUp = 'companyConfirmSingUp',
  resendConfirmationCode = 'resendConfirmationCode',
  //users
  usersLogin = 'usersLogin',
  usersRefreshToken = 'usersRefreshToken',
  usersForgotPassword = 'usersForgotPassword',
  usersVerificationCode = 'usersVerificationCode',
  usersCreateNewPassword = 'usersCreateNewPassword',
  signOut = 'signOut',
  setUserLanguage = 'setUserLanguage',
  usersVerifyCode = 'usersVerifyCode',
  fetchUserProfile = 'fetchUserProfile',
  //locations
  getLocations = 'getLocations',
  getLocationById = 'getLocationById',
  addLocation = 'addLocation',
  updateLocation = 'updateLocation',
  deleteLocation = 'deleteLocation',
  //department
  getDepartmentsByCompany = 'getDepartmentsByCompany',
  createDepartment = 'createDepartment',
  deleteDepartment = 'deleteDepartment',
  //employees
  createEmployee = 'createEmployee',
  fetchEmployees = 'fetchEmployees',
  editEmployee = 'editEmployee',
  deleteEmployee = 'deleteEmployee',
  editDepartment = 'editDepartment',
  //hr
  createHr = 'createHr',
  createAdmin = 'createAdmin',
  fetchHrUsers = 'fetchHrUsers',
  fetchHrs = 'fetchHrs',
  editHr = 'editHr',
  editAdmin = 'editAdmin',
  deleteHr = 'deleteHr',
  deleteAdmin = 'deleteAdmin',
}
