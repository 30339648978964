import React, { FC } from 'react';
import { Navigate } from 'react-router-dom';

import { getToken } from 'application/helpers/sessionStorage/auth';

import { authService } from 'common';

import { PathNames } from './constants';

const ProtectedRoute: FC<{ children: any }> = ({ children }) => {
  const isAuth = authService.checkAuth();
  const token = getToken();

  if (!isAuth && !token) {
    return <Navigate to={PathNames.login} />;
  }
  return children;
};

export default ProtectedRoute;
