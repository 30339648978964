import React, { FC } from 'react';

import { DeleteIcon, EditIcon } from 'application/assets';

import { LocationItemProps } from './modules';
import { StyledTr, ActionsStyled } from './styles';
import { featuresPermissions } from 'application/helpers/featuresPermissions';

const LocationItem: FC<LocationItemProps> = ({ item, onEdit, onDelete }) => {
  const { hasLocationManagementPermission } = featuresPermissions();

  return (
    <>
      <StyledTr>
        <td>{item.name}</td>
        <td>{item.numberOfEmployees}</td>
        <td>
          <ActionsStyled>
            <button
              type="button"
              onClick={() => onEdit(item)}
              disabled={!hasLocationManagementPermission}
            >
              <EditIcon />
            </button>
            <button
              type="button"
              onClick={() => onDelete(item)}
              disabled={!hasLocationManagementPermission}
            >
              <DeleteIcon />
            </button>
          </ActionsStyled>
        </td>
      </StyledTr>
    </>
  );
};

export default LocationItem;
