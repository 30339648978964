import React, { FC } from 'react';
import { useLocation } from 'react-router-dom';

import MyProfile from 'application/components/MyProfile';
import { PathNames } from 'application/routes/constants';

import AccordionSettings from '../AccordionSettings';
import { Wrapper } from './styles';

const SecondMenu: FC = () => {
  const location = useLocation();
  const isSettingPage = location.pathname.includes(PathNames.settings);
  const showMenu = isSettingPage;

  return (
    <Wrapper showMenu={showMenu}>
      <MyProfile />
      {isSettingPage && <AccordionSettings />}
    </Wrapper>
  );
};

export default SecondMenu;
