import React, { FC } from 'react';
import { AccordionSettingsStyled } from './styles';
import MenuItem from '../SecondMenuItem';
import { useSettingsList } from 'features/aside-menu/hooks/useSettingsList';

const AccordionSettings: FC = () => {
  const settingsOptions = useSettingsList();

  return (
    <AccordionSettingsStyled>
      <div className="accordion-list">
        {settingsOptions.map((item, index) => (
          <MenuItem
            path={item.path}
            name={item.name}
            key={index}
            Image={item.image}
          />
        ))}
      </div>
    </AccordionSettingsStyled>
  );
};
export default AccordionSettings;
