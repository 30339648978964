import styled from '@emotion/styled';
import { commonColors } from 'application/theme';

interface DropdownStyledProps {
  isActive?: boolean;
  disabled?: boolean;
  isError?: boolean;
}

interface OptionItemWrapperProps {
  forDelete: boolean;
}

interface OptionItemProps {
  selected: boolean;
}

export const DropdownStyled = styled.div<DropdownStyledProps>`
  display: grid;
  grid-auto-rows: max-content;
  grid-template-columns: 100%;
  gap: 6px;
  width: 100%;
  position: relative;

  > label {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.08px;
    color: ${({ isError, disabled }) =>
      isError
        ? commonColors.error2
        : disabled
        ? commonColors.grey400
        : commonColors.grey600};
  }

  > span {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.07px;
    color: ${({ isError, disabled }) =>
      isError
        ? commonColors.error2
        : disabled
        ? commonColors.grey400
        : commonColors.grey600};
  }
  h2 {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  > section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 14px;
    width: 100%;
    height: 44px;
    background: ${({ isActive, disabled, isError }) =>
      isError
        ? commonColors.error3
        : isActive
        ? commonColors.blue50
        : commonColors[disabled ? 'grey50' : 'white']};
    border: 1px solid
      ${({ isActive, isError }) =>
        isError
          ? commonColors.error2
          : isActive
          ? commonColors.blue800
          : commonColors.grey300};
    box-shadow: 1px 1px 2px rgba(16, 24, 40, 0.12);
    border-radius: 8px;
    outline: none;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.005em;
    color: ${commonColors.grey700};
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

    > div {
      display: flex;
      align-items: center;
      width: calc(100% - 36px);

      > svg {
        margin-right: 8px;
        min-width: 22px;
        height: 22px;
        path {
          fill: ${({ disabled }) =>
            disabled ? commonColors.grey300 : commonColors.grey500};
        }
      }
    }
    > h2 {
      margin-right: 36px;
    }
    span {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      color: ${({ disabled }) =>
        disabled ? commonColors.grey300 : commonColors.grey500};
    }
    > svg {
      width: 24px;
      margin-left: 12px;
      transform: ${({ isActive }) => (isActive ? 'rotate(180deg)' : 'unset')};
      path {
        fill: ${({ disabled }) =>
          disabled ? commonColors.grey300 : commonColors.grey700};
      }
    }
  }
`;

export const OptionsList = styled.div`
  display: grid;
  padding: 0 8px 8px;
  gap: 4px;
  grid-template-rows: max-content;
  position: absolute;
  z-index: 10;
  top: 100%;
  max-height: 240px;
  overflow-y: auto;
  width: 100%;
  border-radius: 8px;
  border: 1px solid ${commonColors.grey300};
  background: ${commonColors.white};
  box-shadow: 0 10px 45px 0 rgba(14, 40, 83, 0.07);
  cursor: pointer;

  svg {
    z-index: 11;
  }

  div[type='search'] {
    position: sticky;
    top: 0;
    z-index: 20;
    padding: 8px 0 6px;
    background: ${commonColors.white};
  }
`;

export const OptionItemWrapper = styled.div<OptionItemWrapperProps>`
  border-radius: 4px;
  background: ${({ forDelete }) =>
    forDelete ? commonColors.grey100 : 'transparent'};
`;

export const OptionItem = styled.div<OptionItemProps>`
  display: flex;
  justify-content: space-between;
  padding: 4px 6px;
  align-items: center;
  border-radius: 4px;
  background: ${({ selected }) =>
    selected ? commonColors.blue100 : 'transparent'};

  color: ${commonColors.grey800};
  font-size: 16px;
  font-weight: 500;
  line-height: 22.4px;
  letter-spacing: 0.08px;

  svg {
    width: 20px;
    height: 20px;
    display: none;

    path {
      fill: ${commonColors.grey700};
    }
  }

  &:hover {
    background: ${commonColors.grey100};

    svg {
      display: block;
    }
  }
`;

export const LoaderSection = styled.div`
  display: flex;
  justify-content: center;
  margin: 6px 6px;
  padding: 12px 8px;
  background: ${commonColors.grey50};
  border-radius: 4px;
  gap: 12px;

  > svg {
    animation: anim__rotate 2s linear infinite;
  }
`;

export const DeleteSection = styled.div`
  display: flex;
  flex-direction: column;
  margin: 6px 6px;
  padding: 12px 8px;
  background: ${commonColors.grey50};
  border-radius: 4px;
  gap: 12px;
`;

export const Message = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 19.6px;
  color: ${commonColors.grey900};
  font-weight: 700;
  gap: 8px;

  svg {
    path {
      width: 20px;
      height: 20px;
      fill: ${commonColors.success1};
    }
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;

  > button {
    width: fit-content;
  }
`;

export const EmptyInput = styled.div`
  padding: 4px 6px;
  align-items: center;
  border-radius: 4px;
  color: ${commonColors.grey800};
  font-size: 16px;
  font-weight: 500;
  line-height: 22.4px;
  letter-spacing: 0.08px;
`;

export const ErrorList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  font-size: 14px;
  line-height: 19.6px;
  color: ${commonColors.grey900};
  font-weight: 500;

  > div {
    display: flex;
    align-items: center;
    gap: 4px;

    svg {
      path {
        width: 16px;
        height: 16px;
        fill: ${commonColors.error2};
      }
    }
  }
`;
