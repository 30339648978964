import styled from '@emotion/styled';
import ExpandLess from 'application/assets/expand-less.svg';
import ExpandMore from 'application/assets/expand-more.svg';
import { commonColors } from 'application/theme';

interface StyledProps {
  isError?: boolean;
  disabled?: boolean;
  type?: string;
  isActive?: boolean;
}

export const StyledInput = styled.div<StyledProps>`
  display: grid;
  gap: 6px;

  section {
    position: relative;
    display: flex;
    background: ${({ isError, isActive, disabled }) =>
      disabled
        ? commonColors.grey50
        : isError
        ? commonColors.error3
        : commonColors[isActive ? 'blue50' : 'white']};
    border: 1px solid
      ${({ isError, isActive }) =>
        isError
          ? commonColors.error2
          : commonColors[isActive ? 'blue800' : 'grey300']};
    box-shadow: 1px 1px 2px rgba(16, 24, 40, 0.12);
    border-radius: 8px;
  }

  span,
  label {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.005em;
    color: ${commonColors['grey600']};
  }

  > span,
  label {
    color: ${({ isError, disabled }) =>
      isError
        ? commonColors.error2
        : commonColors[disabled ? 'grey400' : 'grey600']};
  }

  label {
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
  }

  .react-tel-input {
    background: none;
    border: none;
    outline: none;
    font-family: 'Mulish';
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.005em;
    color: ${commonColors.grey700};

    input {
      background: ${({ disabled }) =>
        disabled ? commonColors.grey50 : commonColors.white};
      opacity: ${({ disabled }) => (disabled ? 0.7 : 1)};
    }

    .form-control:focus {
      box-shadow: unset;
    }

    .country-list {
      left: 0;
      margin: 1px 0;
      border-radius: 8px;
      border: 1px solid ${commonColors.grey300};
      background: ${commonColors.white};
      box-shadow: 0px 2px 12px 0px rgba(56, 56, 56, 0.08);
      .search {
        padding: 10px 9px 9px 10px;
      }
      .search-box {
        margin: 0px;
        padding: 10px 9px 9px 10px;
        width: 100%;
        background: ${commonColors.white};
        border: 1px solid ${commonColors.grey300};
        box-shadow: 1px 1px 2px rgba(16, 24, 40, 0.12);
        border-radius: 8px;
        outline: none;
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
      }
      .country {
        padding: 8px 9px 8px 46px;
        &:hover {
          background: ${commonColors.blue100};
        }
        &.highlight {
          background: ${commonColors.blue100};
        }
      }
    }

    .flag-dropdown {
      opacity: ${({ disabled }) => (disabled ? 0.7 : 1)};
      .selected-flag {
        padding: 0 45px 0 14px;
        width: 80px;
        .arrow {
          border: 0;
          height: 10px;
          width: 10px;
          background-image: url(${ExpandMore});
          background-repeat: no-repeat;
          background-position: center;
          top: unset;
          margin-top: 0;

          &.up {
            background-image: url(${ExpandLess});
          }
          width: 24px;
          height: 24px;
        }

        border-right: 1px solid
          ${({ isError, isActive }) =>
            isError
              ? commonColors.error2
              : commonColors[isActive ? 'blue800' : 'grey300']};
      }
    }

    > input {
      width: 100%;
      height: 44px;
      background: #ffffff;
      border-radius: 8px;
      color: ${commonColors.grey700};
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
      padding: 0;
      padding-left: 94px;
      border: none;
      outline: none;

      :focus {
        border: none;
        outline: none;
      }
    }
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px
      ${({ isActive }) => (isActive ? commonColors.blue50 : commonColors.white)}
      inset !important;
  }
`;
