import styled from '@emotion/styled';
import { commonColors } from 'application/theme';

export const ModalStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  > h1 {
    color: ${commonColors.grey900};
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    letter-spacing: 0.2px;
  }
  > h2 {
    color: ${commonColors.grey700};
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    letter-spacing: 0.09px;
  }
`;

export const OptionsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  > div {
    display: flex;
    align-items: start;
    gap: 8px;

    svg {
      width: 20px;
      height: 20px;
      min-width: 20px;
      min-height: 20px;
      margin-top: 2px;

      path {
        fill: ${commonColors.error2};
      }
    }

    p {
      color: ${commonColors.grey700};
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%;
      letter-spacing: 0.09px;
    }
  }
`;
