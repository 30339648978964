import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { SupportedLanguageIso } from '@docbay/schemas';

import { Divider, Input, PrimaryButton } from 'application/components';
import { PathNames } from 'application/routes/constants';
import { getLanguageFromLocalStorage } from 'application/helpers/language';
import { UsersActions } from 'application/store/reducers/Users/actions';
import { useAppDispatch, useAppSelector } from 'application/hooks/redux';
import { isResponseFulfilled } from 'application/helpers/responseHelper';
import { requestSelector } from 'application/store/reducers/request/selectors';
import {
  getToken,
  setProfilePermissions,
} from 'application/helpers/sessionStorage/auth';

import { Loader } from 'common/loader';

import { useLoginSchema } from '../helpers/useLoginSchema';
import LayoutComponent from '../LayoutComponent';
import { login } from '../authService';

import { LoginFormData } from './models';
import {
  Container,
  TextSection,
  FormStyled,
  RowStyled,
  FooterStyled,
  StyledError,
} from './style';

const Login: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const loginSchema = useLoginSchema();
  const { loading } = useAppSelector(requestSelector).usersLogin;

  const [showError, setShowError] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginFormData>({
    resolver: yupResolver(loginSchema),
    mode: 'onChange',
  });

  // TODO after logout implementation
  useEffect(() => {
    const token = getToken();
    if (token) return navigate(PathNames.home);
  }, []);

  const onSubmit = async (data: LoginFormData) => {
    const language = getLanguageFromLocalStorage();

    const response = await dispatch(
      UsersActions.usersLogin({
        email: data.email,
        password: data.password,
        languageIso: language as SupportedLanguageIso,
      }),
    );
    isResponseFulfilled(
      response,
      async () => {
        const response = await dispatch(UsersActions.fetchUserProfile());
        login();
        isResponseFulfilled(
          response,
          async () => {
            setProfilePermissions(response.payload.permissions);
          },
          () => {
            setShowError(true);
          },
        );
        login();
      },
      () => {
        setShowError(true);
      },
    );
  };

  return (
    <LayoutComponent>
      {loading && <Loader />}
      <Container className="mr-120">
        <TextSection>
          <h1>{t('log_in.title')}</h1>
          <h2>{t('log_in.sub_title')}</h2>
        </TextSection>
        <FormStyled onSubmit={handleSubmit(onSubmit)}>
          <Input
            id="email"
            label={t('global.email') || ''}
            type="email"
            placeholder={t('global.email_placeholder') || ''}
            errorMessage={errors.email?.message}
            register={register}
          />
          <Input
            id="password"
            label={t('global.password')!}
            type="password"
            placeholder={t('global.password_placeholder')!}
            errorMessage={errors.password?.message}
            register={register}
          />
          <RowStyled>
            <Link to={PathNames.forgotPassword}>
              {t('log_in.forgot_password')}
            </Link>
          </RowStyled>
          <PrimaryButton type="submit">{t('global.login')}</PrimaryButton>
          {showError && (
            <StyledError>{t('errors.incorrect_credentials')}</StyledError>
          )}
        </FormStyled>
        <FooterStyled>
          <div>
            <Divider />
            <p>{t('global.or')}</p>
            <Divider />
          </div>
          <div>
            <p>{t('log_in.dont_have_an_account')}</p>
            <Link to={PathNames.companyRegistration}>
              {t('global.register')}
            </Link>
          </div>
        </FooterStyled>
      </Container>
    </LayoutComponent>
  );
};

export default Login;
