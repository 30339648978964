import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Checkbox,
  Modal,
  PrimaryButton,
  SecondaryButton,
} from 'application/components';
import { FrameIcon } from 'application/assets';
import {
  CompanyDataSessionStorage,
  setCompanyDataToSessionStorage,
} from 'application/helpers/sessionStorage/companyRegistration';

import { BackModalProps } from './models';
import { CheckboxStyled, HeaderWrapper, ModalStyled, Title } from './styles';

const BackModal: FC<BackModalProps> = ({ opened, onClose, onSubmit }) => {
  const { t } = useTranslation();
  const [notAskAnymore, setNotAskAnymore] = useState(false);

  const hideModal = () => {
    if (notAskAnymore) {
      setCompanyDataToSessionStorage(
        CompanyDataSessionStorage.NOT_ASK_ANYMORE,
        true,
      );
    }
  };

  const onStayHere = () => {
    hideModal();
    onClose();
  };

  const handleBack = () => {
    onSubmit();
    onClose();
    hideModal();
  };

  return (
    <Modal opened={opened} onClose={onClose}>
      <ModalStyled>
        <HeaderWrapper>
          <FrameIcon />
          <Title>{t('company_registration.back_modal.confirm_title')}</Title>
        </HeaderWrapper>
        <section>
          <SecondaryButton onClick={handleBack}>
            {t('global.back')}
          </SecondaryButton>
          <PrimaryButton onClick={onStayHere}>
            {t('global.stay_here')}
          </PrimaryButton>
        </section>
        <CheckboxStyled>
          <Checkbox
            checked={notAskAnymore}
            id={'showItAgain'}
            onChange={(checked) => setNotAskAnymore(checked)}
            label={t('company_registration.back_modal.show_it_again') || ''}
          />
        </CheckboxStyled>
      </ModalStyled>
    </Modal>
  );
};

export default BackModal;
