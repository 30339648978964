import React, { FC, memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import usePagination from '@mui/material/usePagination';
import { ButtonStyled, PageButton, Wrapper } from './styles';
import { ArrowBackIcon, ArrowForwardIcon } from 'application/assets';
import { PaginationProps } from './modules';

const Pagination: FC<PaginationProps> = ({
  pages,
  setCurrentPage,
  currentPage,
}) => {
  const { t } = useTranslation();
  const { items } = usePagination({
    count: pages,
    page: currentPage,
    onChange: (e, page) => {
      setCurrentPage(page);
    },
  });

  const prevButton = useMemo(
    () => items.find((item) => item.type === 'previous'),
    [items, currentPage],
  );
  const nextButton = useMemo(
    () => items.find((item) => item.type === 'next'),
    [items, currentPage],
  );
  const pageList = useMemo(
    () =>
      items.filter((item) => item.type !== 'next' && item.type !== 'previous'),
    [items, currentPage],
  );

  return (
    <Wrapper>
      <ButtonStyled {...prevButton!} type="button">
        <ArrowBackIcon /> {t('global.back')}
      </ButtonStyled>{' '}
      <div>
        {pageList.map(({ page, type, selected, ...item }, index) => {
          let children = null;
          if (type === 'start-ellipsis' || type === 'end-ellipsis') {
            children = '…';
          } else if (type === 'page') {
            children = (
              <PageButton currentPage={selected} type="button" {...item}>
                {page}
              </PageButton>
            );
          }
          return <div key={index}>{children}</div>;
        })}
      </div>
      <ButtonStyled {...nextButton} type="button">
        {t('global.next')} <ArrowForwardIcon />
      </ButtonStyled>
    </Wrapper>
  );
};

export default memo(Pagination);
