import { createSlice } from '@reduxjs/toolkit';
import { InitialState } from './models';
import {
  createEmployee,
  deleteEmployee,
  editEmployee,
  fetchEmployees,
} from './actions';

const initialState: InitialState = {
  employees: [],
  employeeForAction: null,
  created: false,
  deleted: false,
  edited: false,
  error: '',
  total: 0,
  page: 1,
  totalPages: 0,
};

const employeesManagementSlice = createSlice({
  name: 'departmentsSlice',
  initialState,
  reducers: {
    resetEmployeeForAction: (state) => {
      state.employeeForAction = null;
      state.created = false;
      state.edited = false;
      state.deleted = false;
    },
    setEmployeeForAction: (state, action) => {
      state.employeeForAction = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createEmployee.fulfilled, (state, action) => {
      state.created = true;
      state.employeeForAction = action.payload;
    });
    builder.addCase(fetchEmployees.fulfilled, (state, action) => {
      state.employees = action.payload.entities;
      state.page = action.payload.page!;
      state.totalPages = action.payload.totalPages!;
      state.total = action.payload.total!;
    });
    builder.addCase(editEmployee.fulfilled, (state, action) => {
      state.edited = true;
      state.employeeForAction = action.payload;
    });
    builder.addCase(deleteEmployee.fulfilled, (state) => {
      state.deleted = true;
    });
  },
});

export const { resetEmployeeForAction, setEmployeeForAction } =
  employeesManagementSlice.actions;

export default employeesManagementSlice.reducer;
