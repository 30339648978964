import styled from '@emotion/styled';
import { commonColors } from 'application/theme';

export const TimerWrapper = styled.div`
  display: flex;
  align-items: center;
  > p {
    color: ${commonColors.grey800};
    font-size: 16px;
    font-weight: 500;
    line-height: 22.4px;
  }
  > span {
    color: ${commonColors.green600};
    font-size: 16px;
    font-weight: 700;
    line-height: 140%;
  }
  > svg {
    width: 20px;
    height: 20px;
    min-width: 20px;
    min-height: 20px;
    margin-left: 12px;
    path {
      fill: ${commonColors.green600};
    }
  }
`;
