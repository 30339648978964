import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { emailValidation } from 'application/constants/email';

export const useLoginSchema = () => {
  const { t } = useTranslation();
  const requiredError = t('errors.required');
  const emailError = t('errors.email_must_be_email') || '';

  return yup.object({
    email: yup
      .string()
      .matches(emailValidation, emailError)
      .required(requiredError),
    password: yup.string().required(requiredError),
  });
};
