import {
  HrDepartmentAdminCreateRequest,
  HrDepartmentCompanyResponse,
} from '@docbay/schemas';

export enum CompanyDataSessionStorage {
  STEP = 'STEP',
  FOCUS_ON_EMAIL = 'FOCUS_ON_EMAIL',
  PERSONAL_INFORMATION = 'PERSONAL_INFORMATION',
  COMPANY_INFORMATION = 'COMPANY_INFORMATION',
  TIMER_END_DATE = 'TIMER_END_DATE',
  NOT_ASK_ANYMORE = 'NOT_ASK_ANYMORE',
  HAS_EMAIL_ERROR = 'HAS_EMAIL_ERROR',
  HAS_PHONE_ERROR = 'HAS_PHONE_ERROR',
  EMAIL_OLD = 'EMAIL_OLD',
  PHONE_OLD = 'PHONE_OLD',
  USER_EMAIL = 'USER_EMAIL',
}

export const setCompanyDataToSessionStorage = (
  key: CompanyDataSessionStorage,
  data: any,
) => {
  sessionStorage.setItem(key, JSON.stringify(data));
};

export const getCompanyDataFromSessionStorage = (
  key: CompanyDataSessionStorage,
) => {
  const data = sessionStorage.getItem(key);
  return data ? JSON.parse(data) : null;
};

export const getPersonalInfoFromSessionStorage = () => {
  const data: HrDepartmentAdminCreateRequest | null =
    getCompanyDataFromSessionStorage(
      CompanyDataSessionStorage.PERSONAL_INFORMATION,
    );
  return data;
};

export const getCompanyInfoFromSessionStorage = () => {
  const data: HrDepartmentCompanyResponse | null =
    getCompanyDataFromSessionStorage(
      CompanyDataSessionStorage.COMPANY_INFORMATION,
    );
  return data;
};

export const focusOnEmailInput = () => {
  const focusOnEmail = sessionStorage.getItem(
    CompanyDataSessionStorage.FOCUS_ON_EMAIL,
  );
  const isFocused = focusOnEmail ? JSON.parse(focusOnEmail || '') : false;
  return isFocused;
};

export const setFocusOnEmailSessionStorage = (value: boolean) => {
  sessionStorage.setItem(
    CompanyDataSessionStorage.FOCUS_ON_EMAIL,
    JSON.stringify(value),
  );
};

export const clearCompanyDataFromSessionStorage = () => {
  const itemsToDelete = [
    CompanyDataSessionStorage.STEP,
    CompanyDataSessionStorage.FOCUS_ON_EMAIL,
    CompanyDataSessionStorage.PERSONAL_INFORMATION,
    CompanyDataSessionStorage.COMPANY_INFORMATION,
    CompanyDataSessionStorage.TIMER_END_DATE,
    CompanyDataSessionStorage.NOT_ASK_ANYMORE,
    CompanyDataSessionStorage.HAS_EMAIL_ERROR,
    CompanyDataSessionStorage.HAS_PHONE_ERROR,
    CompanyDataSessionStorage.EMAIL_OLD,
    CompanyDataSessionStorage.PHONE_OLD,
    CompanyDataSessionStorage.USER_EMAIL,
  ];
  itemsToDelete.forEach((item) => {
    sessionStorage.removeItem(item);
  });
};

export const deleteCompanyDataFromSessionStorage = (
  itemsToDelete: CompanyDataSessionStorage[],
) => {
  itemsToDelete.forEach((item) => {
    sessionStorage.removeItem(item);
  });
};
