import { createAsyncThunk } from '@reduxjs/toolkit';
import { RequestEnum } from 'application/store/reducers/request/model';
import {
  HrDepartmentAdminCreateRequest,
  HrDepartmentAdminUpdateRequest,
  HrDepartmentHrCreateRequest,
  HrDepartmentHrUpdateRequest,
} from '@docbay/schemas';
import { HrsManagementAPI } from 'integration/api/hrManagement';

interface Props {
  page?: number;
  limit?: number;
  languageIso?: string;
  hrUserTypes?: string;
  search?: string;
}

export const createHr = createAsyncThunk(
  RequestEnum.createHr,
  async (params: HrDepartmentHrCreateRequest, { rejectWithValue }) => {
    try {
      const { data } = await HrsManagementAPI.createHr(params);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

export const createAdmin = createAsyncThunk(
  RequestEnum.createAdmin,
  async (params: HrDepartmentAdminCreateRequest, { rejectWithValue }) => {
    try {
      const { data } = await HrsManagementAPI.createAdmin(params);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

export const fetchHrUsers = createAsyncThunk(
  RequestEnum.fetchHrUsers,
  async (params: Props, { rejectWithValue }) => {
    try {
      const { data } = await HrsManagementAPI.fetchHrUsers(params);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

export const editHr = createAsyncThunk(
  RequestEnum.editHr,
  async (
    params: { data: HrDepartmentHrUpdateRequest; hrId: string },
    { rejectWithValue },
  ) => {
    try {
      const { data } = await HrsManagementAPI.editHr(params);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

export const editAdmin = createAsyncThunk(
  RequestEnum.editAdmin,
  async (
    params: { data: HrDepartmentAdminUpdateRequest; hrAdminId: string },
    { rejectWithValue },
  ) => {
    try {
      const { data } = await HrsManagementAPI.editAdmin(params);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

export const deleteHr = createAsyncThunk(
  RequestEnum.deleteHr,
  async (hrId: string, { rejectWithValue }) => {
    try {
      const { data } = await HrsManagementAPI.deleteHr(hrId);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

export const deleteAdmin = createAsyncThunk(
  RequestEnum.deleteAdmin,
  async (hrAdminId: string, { rejectWithValue }) => {
    try {
      const { data } = await HrsManagementAPI.deleteAdmin(hrAdminId);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);
