import React, { useMemo } from 'react';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@mui/material/styles';
import { store } from './store';
import { AppRouter } from './routes';
import { materialTheme } from './theme';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import updateLocale from 'dayjs/plugin/updateLocale';
import './App.css';
import { useTranslation } from 'react-i18next';
import { getLanguageFromLocalStorage } from 'application/helpers/language';
dayjs.extend(updateLocale);

dayjs.updateLocale('pt', {
  weekdaysMin: 'dom_seg_ter_qua_qui_sex_sab'.split('_'),
});
dayjs.updateLocale('fr', {
  weekdaysMin: 'di_lu_ma_me_je_ve_sa'.split('_'),
});

const App = () => {
  const { i18n } = useTranslation();

  const currentLanguage = useMemo(() => {
    return getLanguageFromLocalStorage();
  }, [i18n.language]);

  return (
    <ThemeProvider theme={materialTheme}>
      <LocalizationProvider
        dateAdapter={AdapterDayjs}
        adapterLocale={currentLanguage}
      >
        <Provider store={store}>
          <AppRouter />
        </Provider>
      </LocalizationProvider>
    </ThemeProvider>
  );
};

export default App;
