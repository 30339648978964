import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';

import { AgreementAndPolicyType } from 'application/store/reducers/AgreementsAndPolicies/models';
import { Modal } from 'application/components';

import { useAgreementsAndPoliciesModal } from './hooks/useAgreementsAndPoliciesModal';
import { MarkdownStyled, ModalContentWrapper } from './styles';

const AgreementsAndPoliciesModal: FC = () => {
  const { t } = useTranslation();
  const { isModalOpen, policyType, closeModal } =
    useAgreementsAndPoliciesModal();
  const modalContent = useMemo(() => {
    switch (policyType) {
      case AgreementAndPolicyType.COOKIES:
        return t('agreements_and_policies.cookie_policy.markdown');
      case AgreementAndPolicyType.PRIVACY_POLICY:
        return t('agreements_and_policies.privacy_policy.markdown');
      case AgreementAndPolicyType.TERMS_AND_CONDITIONS:
        return t('agreements_and_policies.terms_and_conditions.markdown');
      default:
        return t('agreements_and_policies.terms_and_conditions.markdown');
    }
  }, [policyType, t]);
  return (
    <>
      <Modal opened={isModalOpen} onClose={closeModal} width={'90vw'}>
        <ModalContentWrapper>
          <MarkdownStyled
            remarkPlugins={[remarkGfm]}
            rehypePlugins={[rehypeRaw]}
          >
            {modalContent}
          </MarkdownStyled>
        </ModalContentWrapper>
      </Modal>
    </>
  );
};

export default AgreementsAndPoliciesModal;
