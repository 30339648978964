import React, { FC } from 'react';

import { LanguageDropdown } from 'application/components';
import { useLanguage } from 'application/hooks/useLanguage';

import { LogoIcon } from 'common/auth/assets';

import { LayoutComponentProps } from './models';
import { Wrapper, Header, Footer, Container } from './styles';

const LayoutComponent: FC<LayoutComponentProps> = ({ children }) => {
  const { currentLanguage, changeLanguage } = useLanguage();

  return (
    <Wrapper>
      <Header>
        <LogoIcon />
        <LanguageDropdown value={currentLanguage} onChange={changeLanguage} />
      </Header>
      <Container>{children}</Container>
      <Footer>
        <p>© 2024 DocBay</p>
      </Footer>
    </Wrapper>
  );
};

export default LayoutComponent;
