import styled from '@emotion/styled';
import { commonColors } from 'application/theme';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 20px;
  justify-content: flex-end;
  background: ${commonColors.white};
  border-bottom: 1px solid ${commonColors.grey200};
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;

  > div:last-of-type {
    width: 324px;
  }
`;
