import React, { FC, JSXElementConstructor } from 'react';
import { MenuItemStyled } from './styles';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { PathNames } from 'application/routes/constants';
import { DocBaySupportLink } from 'application/constants/externalLinks';

interface MenuItemProps {
  path: string;
  name: string;
  Image?: JSXElementConstructor<any>;
  availablePaths?: string[];
}

const MenuItem: FC<MenuItemProps> = ({ path, name, Image, availablePaths }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const isCurrentPage = availablePaths?.includes(location.pathname);
  const isSupportItem = path === PathNames.support;

  const handleChangePath = () => {
    if (!isCurrentPage) {
      navigate(path);
    }
  };

  return (
    <MenuItemStyled>
      {isSupportItem ? (
        <Link to={DocBaySupportLink} target="_blank">
          <button type="button" className={`${isCurrentPage ? 'active' : ''}`}>
            {Image ? (
              <div className="menu_item">
                <Image />
                <p>{name}</p>
              </div>
            ) : (
              name
            )}
          </button>
        </Link>
      ) : (
        <button
          onClick={handleChangePath}
          className={`${isCurrentPage ? 'active' : ''}`}
        >
          {Image ? (
            <div className={'menu_item'}>
              <div className={'image-wrapper'}>
                <Image />
              </div>
              <p>{name}</p>
            </div>
          ) : (
            name
          )}
        </button>
      )}
    </MenuItemStyled>
  );
};

export default MenuItem;
