import {
  UserChangePasswordDto,
  UserLoginDto,
  UserRefreshTokenDto,
  UserSetPasswordDto,
} from '@docbay/schemas';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import { getToken } from 'application/helpers/sessionStorage/auth';

import { ForgotPasswordDto } from 'common/auth/signIn/models';

import { UsersAPI } from 'integration/api/users';

import { RequestEnum } from '../request/model';

const usersLogin = createAsyncThunk(
  RequestEnum.usersLogin,
  async (loginData: UserLoginDto, { rejectWithValue }) => {
    try {
      const { data } = await UsersAPI.usersLogin(loginData);
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

const usersRefreshToken = createAsyncThunk(
  RequestEnum.usersRefreshToken,
  async (refreshTokenData: UserRefreshTokenDto, { rejectWithValue }) => {
    try {
      const { data } = await UsersAPI.usersRefreshToken(refreshTokenData);
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

const usersForgotPassword = createAsyncThunk(
  RequestEnum.usersForgotPassword,
  async (forgotPasswordData: ForgotPasswordDto, { rejectWithValue }) => {
    try {
      const { data } = await UsersAPI.usersForgotPassword(forgotPasswordData);
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error);
    }
  },
);

const usersCreateNewPassword = createAsyncThunk(
  RequestEnum.usersCreateNewPassword,
  async (setPasswordData: UserSetPasswordDto, { rejectWithValue }) => {
    try {
      const { data } = await UsersAPI.usersCreateNewPassword(setPasswordData);
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

const usersVerifyCode = createAsyncThunk(
  RequestEnum.usersVerifyCode,
  async (
    usersVerifyCodeData: { email: string; code: string },
    { rejectWithValue },
  ) => {
    try {
      const { data } = await UsersAPI.usersVerifyCode(usersVerifyCodeData);
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

const signOut = createAsyncThunk(
  RequestEnum.signOut,
  async (_, { rejectWithValue }) => {
    try {
      const { data: responseData } = await UsersAPI.signOut();
      return responseData;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

const setUserLanguage = createAsyncThunk(
  RequestEnum.setUserLanguage,
  async (languageIso: string, { rejectWithValue }) => {
    try {
      const { data } = await UsersAPI.setUserLanguage(languageIso);
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

const fetchUserProfile = createAsyncThunk(
  RequestEnum.fetchUserProfile,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await UsersAPI.fetchUserProfile();
      return data;
    } catch (e) {
      const error = e as AxiosError;
      return rejectWithValue(error.message);
    }
  },
);

export const UsersActions = {
  usersLogin,
  usersRefreshToken,
  usersForgotPassword,
  usersCreateNewPassword,
  usersVerifyCode,
  signOut,
  setUserLanguage,
  fetchUserProfile,
};
