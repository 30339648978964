import styled from '@emotion/styled';

import checked from 'application/assets/checked.svg';
import { commonColors } from 'application/theme';

export const StyledCheckbox = styled.div`
  display: flex;
  align-items: center;
  height: fit-content;
  min-height: 22px;
  > input {
    opacity: 0;
  }
  > label {
    min-height: 16px;
    font-weight: 500;
    font-size: 16px;
    color: ${commonColors.grey700};
  }
  > input + label {
    position: relative;
    padding-left: 10px;
    cursor: pointer;
    &:before {
      content: '';
      position: absolute;
      left: -20px;
      top: 8px;

      transform: translate(0, -50%);
      width: 20px;
      height: 20px;
      background: ${commonColors.white};
      border: 1px solid ${commonColors.grey400};
      border-radius: 3px;
      margin: 0;
    }
    &:after {
      content: '';
      position: absolute;
      top: -3px;
      left: -20px;
      width: 22px;
      height: 22px;
      border-radius: 3px;
      background-color: ${commonColors.blue300};
      background-image: url(${checked});
      background-position: center;
      background-repeat: no-repeat;
      background-size: 13px;
    }
  }
  > input:not(:checked) + label {
    &:after {
      opacity: 0;
      transform: scale(0);
    }
  }
  > input:disabled:not(:checked) + label {
    &:before {
      box-shadow: none;
    }
  }
  > input:checked + label {
    &:after {
      opacity: 1;
      transform: scale(1);
    }
  }
  > input:disabled:checked + label {
    &:after {
      color: #999;
    }
  }
  > input:disabled + label {
    color: #aaa;
  }
`;
