import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { HrDepartmentLocationResponseDto } from '@docbay/schemas';

import { InitialState } from './models';
import { LocationsActions } from './actions';

const initialState: InitialState = {
  locations: [],
  total: 0,
  page: 1,
  totalPages: 0,
  location: null,
};

const locationsSlice = createSlice({
  name: 'locationsSlice',
  initialState,
  reducers: {
    setLocation: (
      state,
      action: PayloadAction<HrDepartmentLocationResponseDto | null>,
    ) => {
      state.location = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(
      LocationsActions.getLocations.fulfilled,
      (state, action) => {
        state.locations = action.payload.entities;
        state.total = action.payload.total || 0;
        state.page = action.payload.page || 1;
        state.totalPages = action.payload.totalPages || 0;
      },
    );
    builder.addCase(
      LocationsActions.getLocationById.fulfilled,
      (state, action) => {
        state.location = action.payload;
      },
    );
  },
});

export const { setLocation } = locationsSlice.actions;
export default locationsSlice.reducer;
