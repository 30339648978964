export const colors = {
  // Grey
  grey900: '#101828',
  grey800: '#1C273B',
  grey700: '#344054',
  grey600: '#475467',
  grey500: '#667085',
  grey400: '#98A2B3',
  grey300: '#D0D5DD',
  grey200: '#EAECF0',
  grey100: '#F2F4F7',
  grey50: '#F9FAFB',
  // Blue (main/light)
  blue900: '#014756',
  blue800: '#0989A5',
  blue700: '#00A4BF',
  blue600: '#00BADB',
  blue500: '#00CAEF',
  blue400: '#00D5F5',
  blue300: '#6BE4FF',
  blue200: '#AAEEFD',
  blue100: '#E1F9FE',
  blue50: '#F5FDFF',
  // Blue Dark (m)/700
  blueDark700: '#4697A1',
  blueDark100: '#B8EBF0',
  // Green
  green600: '#4F9E88',
  green300: '#79C6B4',
  green100: '#C5E5DE',
  green50: '#E7F4F2',
  // Purple
  purple100: '#CE9FD6',
  // Additional colours
  error1: '#CA0000',
  error2: '#F24822',
  error3: '#FDF7F7',
  error4: '#CA0000',
  errorBackground: '#FFF2F0',

  success1: '#1BA904',
  success2: '#23E771',
  success3: '#00A4BF',
  success4: '#EEFCF6',
  white: '#FFFFFF',
  menu: '#032530',
  background: '#f0fbff',
  navigator: '#29464F',
  navigatorHover: '#043443',
  warning: '#B54708',
  warning2: '#DC6803',
  warning3: '#FFFCF5',
  positive: '#E2FDEE',
  black: '#000000',
  onlineGreen: '#2CC069',
  sideMenu: '#002831',
};

export type ColorsPallet = typeof colors;
