import styled from '@emotion/styled';
import { commonColors } from 'application/theme';

export const ModalStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: center;

  > section {
    display: flex;
    width: 100%;
    gap: 20px;
    justify-content: space-between;
  }
`;

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;
  > svg {
    width: 82px;
    height: 82px;
  }
`;

export const Title = styled.h1`
  color: ${commonColors.grey900};
  text-align: center;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0.2px;
`;

export const CheckboxStyled = styled.div`
  > div {
    input + label:before {
      width: 18px;
      height: 18px;
    }
  }
`;
