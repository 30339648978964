import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import {
  ConfirmModal,
  DeleteConfirmModal,
  SuccessModal,
} from 'application/components';

import { DeleteModalProps } from './models';
import { Title } from './styles';

const DeleteModal: FC<DeleteModalProps> = ({
  isLoading,
  locationToDelete,
  showDeleteModal,
  handleCloseDeleteModal,
  handleDelete,
  showSuccessDeleteModal,
  onCloseSuccessDeleteModal,
}) => {
  const { t } = useTranslation();
  const showConfirmDeleteModal = !!locationToDelete?.numberOfEmployees;

  return (
    <>
      {showDeleteModal && (
        <>
          {showConfirmDeleteModal ? (
            <DeleteConfirmModal
              opened={showDeleteModal}
              onClose={handleCloseDeleteModal}
              onSubmit={handleDelete}
              title={t('locations.confirm_delete_modal.title')}
              subTitle={t('locations.confirm_delete_modal.sub_title')}
              options={[
                t('locations.confirm_delete_modal.option_1'),
                t('locations.confirm_delete_modal.option_2'),
              ]}
              isLoading={isLoading}
            ></DeleteConfirmModal>
          ) : (
            <ConfirmModal
              opened={showDeleteModal}
              onClose={handleCloseDeleteModal}
              onSubmit={handleDelete}
              styleType="error"
              isLoading={isLoading}
            >
              <Title>
                {t('locations.delete_modal.title')}{' '}
                <span>{locationToDelete?.name}</span>?
              </Title>
            </ConfirmModal>
          )}
        </>
      )}
      {showSuccessDeleteModal && (
        <SuccessModal
          opened={showSuccessDeleteModal}
          onClose={onCloseSuccessDeleteModal}
          firstText={t('locations.location')}
          boldText={locationToDelete?.name}
          actionText={t('locations.success_modal.delete_text')}
        />
      )}
    </>
  );
};

export default DeleteModal;
