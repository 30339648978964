import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Languages } from 'application/constants/languages';
import { getLanguageFromLocalStorage } from 'application/helpers/language';

export const useLanguage = () => {
  const { i18n } = useTranslation();

  const currentLanguage = useMemo(() => {
    const language = getLanguageFromLocalStorage();
    return (language.toUpperCase() || Languages.PT) as Languages;
  }, [i18n.language]);

  const changeLanguage = async (language: Languages) => {
    i18n.changeLanguage(language.toLowerCase());
  };

  return { currentLanguage, changeLanguage };
};
