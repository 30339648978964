import {
  CommonEntitiesListResponse,
  HrDepartmentLocationCreationRequestDto,
  HrDepartmentLocationResponseDto,
} from '@docbay/schemas';

import { GetParams, LocationUpdatePrams } from 'application/types/locations';

import axiosInstance from './axiosInstance';

export const LocationsAPI = {
  getLocations: (params: GetParams) => {
    const { search, ...restParams } = params;
    return axiosInstance.get<
      CommonEntitiesListResponse<HrDepartmentLocationResponseDto>
    >('/locations', {
      params: {
        ...restParams,
        ...(search?.length ? { search } : {}),
      },
    });
  },
  getLocationById: (locationId: string) => {
    return axiosInstance.get<HrDepartmentLocationResponseDto>(
      `/locations/${locationId}`,
    );
  },
  addLocation: (data: HrDepartmentLocationCreationRequestDto) => {
    return axiosInstance.post<HrDepartmentLocationResponseDto>(
      '/locations',
      data,
    );
  },
  updateLocation: (data: LocationUpdatePrams) => {
    const { locationId, ...restData } = data;
    return axiosInstance.patch<HrDepartmentLocationResponseDto>(
      `/locations/${locationId}`,
      restData,
    );
  },
  deleteLocation: (locationId: string) => {
    return axiosInstance.delete(`/locations/${locationId}`);
  },
};
