import { createTheme } from '@mui/material/styles';
import { colors } from './palette';

export const materialTheme = createTheme({
  typography: {
    fontFamily: '"Mulish", sans-serif',
  },
  palette: { common: colors },
});

export const styledTheme = {
  color: colors,
};

export const commonColors = colors;
