import {
  CommonEntitiesListResponse,
  HrDepartmentResponseDto,
} from '@docbay/schemas';
import axiosInstance from './axiosInstance';

interface Props {
  page?: number;
  limit?: number;
  languageIso?: string;
  search?: string;
}

export const DepartmentAPI = {
  getDepartmentsByCompany: (params?: Props | undefined) =>
    axiosInstance.get<CommonEntitiesListResponse<HrDepartmentResponseDto>>(
      '/departments',
      { params },
    ),
  createDepartment: (name: string) =>
    axiosInstance.post<HrDepartmentResponseDto>('/departments', { name }),
  editDepartment: (params: { name: string; departmentId?: string }) =>
    axiosInstance.patch<HrDepartmentResponseDto>(
      `/departments/${params.departmentId}`,
      { name: params.name },
    ),
  deleteDepartment: (params: {
    departmentForDelete: string;
    isForceDelete?: boolean;
  }) =>
    axiosInstance.delete(`/departments/${params.departmentForDelete}`, {
      params: { forceDelete: params.isForceDelete },
    }),
};
