import styled from '@emotion/styled';
import { commonColors } from 'application/theme';

export const MenuItemStyled = styled.div`
  > a {
    text-decoration: none;
  }
  button {
    text-decoration: none;
    width: 100%;
    background: transparent;
    border-radius: 4px;
    border: none;
    text-align: start;
    cursor: pointer;
    padding: 0;
    margin: 0;
  }

  .menu_item {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 4px;
    padding: 10px 8px;
    color: ${commonColors.white};
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16.8px;
    letter-spacing: 0.08px;
    gap: 4px;

    .image-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 4px 8px;
    }

    > svg {
      min-width: 24px;
      min-height: 24px;

      path {
        fill: ${commonColors.white};
      }
    }
    &.stroke-icon {
      path {
        fill: none;
        stroke: ${commonColors.white};
      }
    }
    p {
      text-align: center;
    }
  }

  .active {
    .image-wrapper {
      border-radius: 4px;
      background: ${commonColors.blue200};

      svg {
        path {
          fill: ${commonColors.menu};
        }
      }
      &.stroke-icon {
        path {
          fill: none;
          stroke: ${commonColors.menu};
        }
      }
      &.doc-box-icon {
        svg {
          path {
            fill: none;
            stroke: ${commonColors.menu};
          }
          circle {
            stroke: ${commonColors.menu};
          }
        }
      }
    }
  }
`;
