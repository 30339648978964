export enum Languages {
  PT = 'PT',
  EN = 'EN',
  FR = 'FR',
}

export enum LanguagesCode {
  en = 'en',
  pt = 'pt',
  fr = 'fr',
}

export const languagesList = [Languages.PT, Languages.EN, Languages.FR];

export enum SupportedCountries {
  Portugal = 'PT',
  France = 'FR',
}
