export enum PathNames {
  home = '/',
  error = '/error',
  dashboard = '/dashboard',
  hrManagement = '/hr-management',
  employeesManagement = '/employees-management',
  settings = '/settings',
  quotes = '/quotes',
  support = '/support',
  companyRegistration = '/company-registration',
  login = '/login',
  forgotPassword = '/forgot-password',
  confirmPassword = '/confirm-password',
  createNewPassword = '/create-new-password',
  departments = '/settings/departments',
  locations = '/settings/locations',
}
