import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { SecondaryButton } from 'application/components';
import { HelpIcon } from 'application/assets';
import { useAppSelector } from 'application/hooks/redux';
import { DocBaySupportLink } from 'application/constants/externalLinks';

import { LayoutComponentProps } from './models';
import {
  Wrapper,
  Container,
  DividerStyled,
  HeaderStyled,
  FooterStyled,
  DividerVertical,
  LinkStyled,
} from './styles';
import { useAgreementsAndPoliciesModal } from 'features/agreements-and-policies/hooks/useAgreementsAndPoliciesModal';

const LayoutComponent: FC<LayoutComponentProps> = ({ step, children }) => {
  const { t } = useTranslation();
  const { adminData } = useAppSelector((state) => state.companyRegistration);
  const { openTermsAndConditionsModal, openPrivacyPolicyModal } =
    useAgreementsAndPoliciesModal();

  const email = ` ${adminData.email}`;

  const getText = (key: string) => {
    const text = t(`company_registration.header.${key}_${step}`) || '';
    return text;
  };

  return (
    <Wrapper>
      <Container>
        <HeaderStyled>
          <div>
            <h1>{getText('title')}</h1>
            <p>
              {getText('subtitle')}
              {step === 3 && <a href={email}>{email}</a>}
              {step !== 2 && (
                <>
                  <br />
                  {getText('description')}
                </>
              )}
            </p>
          </div>
          <div>
            <LinkStyled to={DocBaySupportLink} target="_blank">
              <SecondaryButton styleType={'color'} type="button">
                <HelpIcon />
                {t('global.help')}
              </SecondaryButton>
            </LinkStyled>
          </div>
        </HeaderStyled>
        <DividerStyled />
        {children}
      </Container>
      <FooterStyled>
        <p>{t('company_registration.footer.copyright')}</p>
        <div>
          <button type="button" onClick={openPrivacyPolicyModal}>
            {t('company_registration.footer.privacy_policy')}
          </button>
          <DividerVertical />
          <button type="button" onClick={openTermsAndConditionsModal}>
            {t('company_registration.footer.terms_and_conditions')}
          </button>
        </div>
      </FooterStyled>
    </Wrapper>
  );
};

export default LayoutComponent;
