import React, { lazy, Suspense } from 'react';

import { LayoutComponent, Loader } from 'common';

import { PathNames } from './constants';

import ErrorPage from '../pages/ErrorPage';

const HomePage = lazy(() => import('../pages/HomePage'));
const CompanyRegistrationPage = lazy(() =>
  import('../pages/CompanyRegistrationPage'),
);
const LoginPage = lazy(() => import('../../common/auth/signIn/Login'));
const ForgotPassword = lazy(() =>
  import('../../common/auth/forgotPassword/ForgotPasswordPage'),
);
const CreateNewPassword = lazy(() =>
  import('../../common/auth/forgotPassword/CreateNewPasswordPage'),
);
const ConfirmPasswordChangePage = lazy(() =>
  import('../../common/auth/forgotPassword/ConfirmPasswordChangePage'),
);
const HrManagementPage = lazy(() => import('../pages/HrManagementPage'));

const EmployeesManagementPage = lazy(() =>
  import('../pages/EmployeesManagementPage'),
);
const DepartmentsPage = lazy(() => import('../pages/DepartmentsPage'));
const LocationsPage = lazy(() => import('../pages/LocationsPage'));

export const publicRouts = [
  {
    path: PathNames.login,
    title: 'Login',
    component: (
      <Suspense fallback={<Loader />}>
        <LoginPage />
      </Suspense>
    ),
  },
  {
    path: PathNames.forgotPassword,
    title: 'Forgot Password',
    component: (
      <Suspense fallback={<Loader />}>
        <ForgotPassword />
      </Suspense>
    ),
  },
  {
    path: PathNames.confirmPassword,
    title: 'Confirm Password Change',
    component: (
      <Suspense fallback={<Loader />}>
        <ConfirmPasswordChangePage />
      </Suspense>
    ),
  },
  {
    path: PathNames.createNewPassword,
    title: 'Create New Password',
    component: (
      <Suspense fallback={<Loader />}>
        <CreateNewPassword />
      </Suspense>
    ),
  },
  {
    path: PathNames.companyRegistration,
    title: 'Company registration',
    component: (
      <Suspense fallback={<Loader />}>
        <CompanyRegistrationPage />
      </Suspense>
    ),
  },

  {
    path: '/*',
    title: 'Error',
    component: <ErrorPage />,
  },
];

export const authRouts = [
  {
    path: PathNames.home,
    title: '/',
    component: (
      <Suspense fallback={<Loader />}>
        <LayoutComponent>
          <HomePage />
        </LayoutComponent>
      </Suspense>
    ),
  },
  {
    path: PathNames.hrManagement,
    title: 'HR Management',
    component: (
      <Suspense fallback={<Loader />}>
        <HrManagementPage />
      </Suspense>
    ),
  },
  {
    path: PathNames.employeesManagement,
    title: 'Employees Management',
    component: (
      <Suspense fallback={<Loader />}>
        <EmployeesManagementPage />
      </Suspense>
    ),
  },
  {
    path: PathNames.locations,
    title: 'Locations',
    component: (
      <Suspense fallback={<Loader />}>
        <LocationsPage />
      </Suspense>
    ),
  },
  {
    path: PathNames.departments,
    title: 'Departments',
    component: (
      <Suspense fallback={<Loader />}>
        <DepartmentsPage />
      </Suspense>
    ),
  },
];
