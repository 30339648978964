import React, { FC } from 'react';
import { Modal as ModalMui } from '@mui/material';
import { CloseIcon, SuccessIcon } from 'application/assets';

import { ModalProps } from './models';
import { Wrapper } from './styles';
import { SecondaryButton } from '../index';
import { useTranslation } from 'react-i18next';

const Modal: FC<ModalProps> = ({
  opened,
  onClose,
  firstText,
  boldText,
  actionText,
}) => {
  const { t } = useTranslation();

  return (
    <ModalMui open={opened}>
      <Wrapper>
        <CloseIcon onClick={onClose} />
        <div>
          <SuccessIcon />
          <p>
            {firstText} {boldText && <span>{boldText}</span>} {actionText}
          </p>
        </div>
        <SecondaryButton type={'button'} onClick={onClose}>
          {t('global.ok')}
        </SecondaryButton>
      </Wrapper>
    </ModalMui>
  );
};

export default Modal;
