import styled from '@emotion/styled';
import { commonColors } from 'application/theme';

export const Wrapper = styled.div`
  display: flex !important;
  width: fit-content !important;
  font-size: 16px;
  line-height: 22px;
  font-weight: 500;
  color: ${commonColors.grey900};
  margin-bottom: 8px;

  div {
    padding: 10px 16px;
    background: ${commonColors.blue100};
    border: 1px solid ${commonColors.grey300};
    font-weight: 700;
  }

  a {
    padding: 10px 16px;
    color: ${commonColors.grey900};
    background: ${commonColors.white};
    border: 1px solid ${commonColors.grey300};
    font-weight: 400;
    text-decoration: none;
  }

  > :nth-child(1) {
    border-right: unset;
    border-radius: 8px 0 0 8px;
  }

  > :nth-child(2) {
    border-radius: 0 8px 8px 0;
  }
`;
