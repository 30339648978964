import { StorageFields } from 'application/constants/localStorage';
import { PathNames } from 'application/routes/constants';
import { store } from 'application/store';
import { clearStore } from 'application/store/reducers/Users/UsersSlice';

const logoutChannel = new BroadcastChannel('logout');
const loginChannel = new BroadcastChannel('login');

export const logout = () => {
  logoutChannel.postMessage('Logout');
  window.location.href = window.location.origin + PathNames.login;
  store?.dispatch(clearStore());
};

export const login = () => {
  loginChannel.postMessage('Login');
  window.location.href = window.location.origin + `${PathNames.home}`;
};

export const authService = {
  saveAccessToken(jwt: string) {
    localStorage.setItem(StorageFields.fbToken, jwt);
  },

  getAccessToken() {
    return localStorage.getItem(StorageFields.fbToken);
  },

  checkAuth() {
    return !!localStorage.getItem(StorageFields.fbToken);
  },

  removeToken() {
    localStorage.removeItem(StorageFields.fbToken);
  },

  eraseSessionData() {
    this.removeToken();
  },

  saveEmail(email: string) {
    localStorage.setItem(StorageFields.email, email);
  },

  getEmail() {
    return localStorage.getItem(StorageFields.email);
  },

  saveCode(code: number) {
    localStorage.setItem(StorageFields.verificationCode, String(code));
  },

  getEmailVerificationCode() {
    return localStorage.getItem(StorageFields.verificationCode);
  },

  saveTimer(timer: string) {
    localStorage.setItem(StorageFields.timer, timer);
  },

  getTimer() {
    return localStorage.getItem(StorageFields.timer);
  },

  removeTimer() {
    localStorage.removeItem(StorageFields.timer);
  },

  cleanStorage() {
    localStorage.clear();
    sessionStorage.clear();
  },
};
