import { createSlice } from '@reduxjs/toolkit';
import { InitialState } from './models';

import {
  createDepartment,
  deleteDepartment,
  editDepartment,
  getDepartmentsByCompany,
} from './actions';

const initialState: InitialState = {
  departments: [],
  departmentForAction: null,
  created: false,
  deleted: false,
  edited: false,
  error: '',
  total: 0,
  page: 1,
  totalPages: 0,
};

const departmentsSlice = createSlice({
  name: 'departmentsSlice',
  initialState,
  reducers: {
    resetDeletedDepartment: (state) => {
      state.deleted = false;
    },
    resetDepartmentForAction: (state) => {
      state.departmentForAction = null;
      state.created = false;
      state.edited = false;
      state.deleted = false;
    },
    setDepartmentForAction: (state, action) => {
      state.departmentForAction = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getDepartmentsByCompany.rejected, (state, action) => {
      state.error = action.payload as string;
    });
    builder.addCase(getDepartmentsByCompany.fulfilled, (state, action) => {
      state.departments = action.payload.entities;
      state.total = action.payload.total!;
      state.page = action.payload.page!;
      state.totalPages = action.payload.totalPages!;
    });
    builder.addCase(createDepartment.fulfilled, (state, action) => {
      state.created = true;
    });
    builder.addCase(deleteDepartment.fulfilled, (state) => {
      state.deleted = true;
    });
    builder.addCase(editDepartment.fulfilled, (state, action) => {
      state.departmentForAction = action.payload;
      state.edited = true;
    });
  },
});

export const {
  resetDeletedDepartment,
  setDepartmentForAction,
  resetDepartmentForAction,
} = departmentsSlice.actions;

export default departmentsSlice.reducer;
