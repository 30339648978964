import styled from '@emotion/styled';
import { commonColors } from 'application/theme';

export const MenuItemStyled = styled.div`
  button {
    text-decoration: none;
    width: 100%;
    background: transparent;
    border-radius: 4px;
    border: none;
    text-align: start;
    cursor: pointer;
    padding: 0;
    margin: 0;
    color: ${commonColors.white};
  }

  svg {
    width: 20px;
    height: 20px;

    path {
      fill: ${commonColors.menu};
    }
  }

  .active {
    .image-wrapper {
      border-radius: 4px;
      background: ${commonColors.blue800};

      svg {
        path {
          fill: ${commonColors.menu};
        }
      }
      &.stroke-icon {
        path {
          fill: none;
          stroke: ${commonColors.menu};
        }
      }
      &.doc-box-icon {
        svg {
          path {
            fill: none;
            stroke: ${commonColors.menu};
          }
          circle {
            stroke: ${commonColors.menu};
          }
        }
      }
    }
  }
`;
