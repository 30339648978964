import styled from '@emotion/styled';
import { commonColors } from 'application/theme';

export const HeaderStyled = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${commonColors.grey300};
  padding: 20px 0;
  margin-bottom: 32px;

  > div {
    display: flex;
    align-items: center;

    h1 {
      color: ${commonColors.grey900};
      font-size: 18px;
      font-weight: 500;
      line-height: 22px;
    }

    a {
      display: flex;
      align-items: center;
      margin-left: 24px;
      text-decoration: none;
      color: ${commonColors.blue800};
      font-size: 16px;
      font-weight: 700;
      line-height: 22px;
      letter-spacing: 0.08px;

      > svg {
        margin-left: 4px;
        path {
          fill: ${commonColors.blue800};
        }
      }
    }
  }

  > a {
    text-decoration: none;
    button {
      height: 38px;
      width: fit-content;
      background: ${commonColors.white};

      > svg {
        path {
          fill: ${commonColors.blue800};
        }
      }
    }
  }
`;
