import { createSlice } from '@reduxjs/toolkit';
import { UsersActions } from './actions';
import {
  getProfilesFromSessionStorage,
  setProfilesInSessionStorage,
  setUsersAuthSessionStorage,
  setUsersRefreshTokenSessionStorage,
} from 'application/helpers/sessionStorage/auth';
import { authService } from 'common';
import { AxiosError } from 'axios';
import { ErrorDto } from 'application/constants/error';

const initialState = {
  error: '',
  isAuth: false,
};

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setAuthError: (state, action) => {
      state.error = action.payload;
    },
    clearStore: () => {
      authService.cleanStorage();
    },
    setIsAuth: (state) => {
      state.isAuth = true;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(UsersActions.usersLogin.rejected, (state, action) => {
      state.isAuth = false;
      state.error = action.payload as string;
    });
    builder.addCase(UsersActions.usersLogin.fulfilled, (state, action) => {
      state.isAuth = true;
      const { token, refreshToken, user } = action.payload;

      setUsersAuthSessionStorage(action.payload);
      setProfilesInSessionStorage([
        {
          id: user?.id!,
          email: user?.email!,
          firstName: user?.firstName!,
          lastName: user?.lastName!,
          photo: user?.photo!,
          token,
          refreshToken,
        },
      ]);
    });
    // refresh token
    builder.addCase(
      UsersActions.usersRefreshToken.rejected,
      (state, action) => {
        state.isAuth = false;
        state.error = action.payload as string;
        authService.cleanStorage();
        clearStore();
      }
    );
    builder.addCase(
      UsersActions.usersRefreshToken.fulfilled,
      (state, action) => {
        state.isAuth = true;

        setUsersRefreshTokenSessionStorage(action.payload);

        const profiles = getProfilesFromSessionStorage();
        const filteredProfiles = profiles?.map((item) => {
          return { ...item, token: action.payload.token };
        });
        setProfilesInSessionStorage(filteredProfiles || []);
      }
    );
    // forgot password
    builder.addCase(
      UsersActions.usersForgotPassword.rejected,
      (state, action) => {
        const error = action.payload as AxiosError<ErrorDto>;
        state.error = error.response?.data.errorCode || '';
      }
    );

    // user sign out
    builder.addCase(UsersActions.signOut.fulfilled, (state) => {
      state.isAuth = false;
    });
  },
});

export const { setAuthError, clearStore, setIsAuth } = usersSlice.actions;

export default usersSlice.reducer;
