import { useTranslation } from 'react-i18next';
import { PathNames } from 'application/routes/constants';

import { ClinicIcon, SubscriptionsIcon, PinIcon } from 'application/assets';

export const useSettingsList = () => {
  const { t } = useTranslation();

  const list = [
    {
      path: '',
      name: t('submenu_list.company_billing'),
      image: SubscriptionsIcon,
    },
    {
      path: PathNames.locations,
      name: t('submenu_list.locations'),
      image: PinIcon,
    },
    {
      path: PathNames.departments,
      name: t('submenu_list.departments'),
      image: ClinicIcon,
    },
    {
      path: '',
      name: t('submenu_list.benefit_plans'),
      image: SubscriptionsIcon,
    },
  ];

  return list;
};
