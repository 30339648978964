import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Header, PrimaryButton } from 'application/components';
import { AddIcon } from 'application/assets';
import { useAppDispatch, useAppSelector } from 'application/hooks/redux';
import { LocationsActions } from 'application/store/reducers/Locations/actions';
import { getLanguageFromLocalStorage } from 'application/helpers/language';
import { useDebounce } from 'application/hooks/useDebounce';
import { requestSelector } from 'application/store/reducers/request/selectors';
import { setLocation } from 'application/store/reducers/Locations/locationsSlice';

import { Loader } from 'common';

import { LocationModal, LocationsTable } from './components';
import { Wrapper, TitleSection } from './styles';
import { featuresPermissions } from 'application/helpers/featuresPermissions';

const defaultLimit = 10;

const Locations: FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { page } = useAppSelector((state) => state.locations);
  const { getLocations, getLocationById } = useAppSelector(requestSelector);

  const { hasLocationManagementPermission } = featuresPermissions();

  const [showModal, setShowModal] = useState(false);
  const [searchItem, setSearchItem] = useState('');

  const search = useDebounce(searchItem, 300);
  const isLoading = getLocations.loading || getLocationById.loading;

  const fetchLocations = async (currentPage?: number) => {
    await dispatch(
      LocationsActions.getLocations({
        limit: defaultLimit,
        languageIso: getLanguageFromLocalStorage(),
        search,
        page: currentPage || page,
      }),
    );
  };

  useEffect(() => {
    fetchLocations(1);
  }, [search]);

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    dispatch(setLocation(null));
  };

  return (
    <Wrapper>
      {isLoading && <Loader />}
      <Header />
      <TitleSection>
        <h1>{t('submenu_list.locations')}</h1>
        {hasLocationManagementPermission && (
          <PrimaryButton type="button" onClick={handleShowModal}>
            <AddIcon /> {t('locations.add_location')}
          </PrimaryButton>
        )}
      </TitleSection>
      <LocationsTable
        searchItem={searchItem}
        setSearchItem={setSearchItem}
        handleShowModal={handleShowModal}
        fetchLocations={fetchLocations}
      />
      {showModal && (
        <LocationModal
          opened={showModal}
          onClose={handleCloseModal}
          fetchLocations={fetchLocations}
        />
      )}
    </Wrapper>
  );
};

export default Locations;
