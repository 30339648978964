import React, { FC, useRef, useState } from 'react';
import dayjs from 'dayjs';
import useOnClickOutside from 'application/hooks/useOnClickOutside';
import { DatePickerStyled, Wrapper } from './styles';
import { DatePickerProps } from './models';

const defaultFormat = 'DD/MM/YYYY';

const DatePickerCustom: FC<DatePickerProps> = ({
  id,
  label,
  value,
  maxDate,
  minDate,
  format = defaultFormat,
  onChange,
  errorMessage,
  disabled = false,
  openTo,
  views,
  isError = false,
}) => {
  const ref = useRef(null);
  const [isActive, setIsActive] = useState(false);

  useOnClickOutside(ref, () => setIsActive(false));

  return (
    <Wrapper
      ref={ref}
      isError={!!errorMessage}
      onMouseDown={() => setIsActive(true)}
    >
      <label htmlFor={id}>{label}</label>

      <DatePickerStyled
        isActive={isActive}
        value={dayjs(value)}
        maxDate={dayjs(maxDate)}
        minDate={minDate ? dayjs(minDate) : dayjs('01/01/1901')}
        onChange={(value) => onChange(dayjs(value as string).toString())}
        format={defaultFormat}
        isError={!!errorMessage || isError}
        disabled={disabled}
        openTo={openTo}
        views={views}
      />
      {errorMessage && <span>{errorMessage}</span>}
    </Wrapper>
  );
};

export default DatePickerCustom;
