import styled from '@emotion/styled';
import { commonColors } from 'application/theme';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  .modal-close-icon {
    display: none;
  }
`;

export const TitleSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  h1 {
    color: ${commonColors.grey900};
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    letter-spacing: 0.28px;
    text-transform: capitalize;
  }

  > button {
    padding: 6px 24px;
    width: fit-content;
    svg {
      path {
        fill: ${commonColors.grey900};
      }
    }
  }
`;
