import {
  CommonEntitiesListResponse,
  HrDepartmentAdminCreateRequest,
  HrDepartmentAdminUpdateRequest,
  HrDepartmentHrCreateRequest,
  HrDepartmentHrResponse,
  HrDepartmentHrUpdateRequest,
  HrDepartmentUserResponse,
} from '@docbay/schemas';

import axiosInstance from './axiosInstance';

interface Props {
  page?: number;
  limit?: number;
  languageIso?: string;
  hrUserTypes?: string;
  search?: string;
}

export const HrsManagementAPI = {
  fetchHrUsers: (params: Props) =>
    axiosInstance.get<CommonEntitiesListResponse<HrDepartmentUserResponse>>(
      '/hr-department/users',
      { params },
    ),
  createHr: (data: HrDepartmentHrCreateRequest) =>
    axiosInstance.post('/hr-department/hrs/sign-up', data),
  createAdmin: (data: HrDepartmentAdminCreateRequest) =>
    axiosInstance.post('/hr-department/admins/sign-up', data),
  fetchHrs: (params: Props) =>
    axiosInstance.get<CommonEntitiesListResponse<HrDepartmentHrResponse>>(
      '/hr-department/hrs',
      { params },
    ),
  editHr: (params: { data: HrDepartmentHrUpdateRequest; hrId: string }) =>
    axiosInstance.patch(`/hr-department/hrs/${params.hrId}`, params.data),
  editAdmin: (params: {
    data: HrDepartmentAdminUpdateRequest;
    hrAdminId: string;
  }) =>
    axiosInstance.patch(
      `/hr-department/admins/${params.hrAdminId}`,
      params.data,
    ),
  deleteHr: (hrId: string) =>
    axiosInstance.delete(`/hr-department/hrs/${hrId}`),
  deleteAdmin: (hrAdminId: string) =>
    axiosInstance.delete(`/hr-department/admins/${hrAdminId}`),
};
