import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  HrDepartmentAdminCreateRequest,
  SupportedLanguageIso,
} from '@docbay/schemas';

import {
  getCompanyInfoFromSessionStorage,
  getPersonalInfoFromSessionStorage,
} from 'application/helpers/sessionStorage/companyRegistration';
import { getLanguageFromLocalStorage } from 'application/helpers/language';

import { CompanyInformationDto, InitialState } from './models';

const companyInfo = getCompanyInfoFromSessionStorage();
const personalInfo = getPersonalInfoFromSessionStorage();

const initialState: InitialState = {
  id: companyInfo?.id || '',
  name: companyInfo?.name || '',
  address: companyInfo?.address || '',
  taxId: companyInfo?.taxId || '',
  adminData: {
    language:
      personalInfo?.language ||
      (getLanguageFromLocalStorage() as SupportedLanguageIso),
    email: personalInfo?.email || '',
    firstName: personalInfo?.firstName || '',
    lastName: personalInfo?.lastName || '',
    jobPosition: personalInfo?.jobPosition || '',
    phone: personalInfo?.phone || '',
    contractualAgreement: true,
  },
};

const companyRegistrationSlice = createSlice({
  name: 'companyRegistrationSlice',
  initialState,
  reducers: {
    setAdminData: (
      state,
      action: PayloadAction<HrDepartmentAdminCreateRequest>,
    ) => {
      state.adminData = action.payload;
    },
    setCompanyInformation: (
      state,
      action: PayloadAction<CompanyInformationDto>,
    ) => {
      const { name, address, taxId } = action.payload;
      state.name = name || state.name;
      state.address = address || state.address;
      state.taxId = taxId || state.taxId;
    },
    clearCompanyData: (state) => {
      state.id = '';
      state.name = '';
      state.address = '';
      state.taxId = '';
      state.adminData = {
        language: getLanguageFromLocalStorage() as SupportedLanguageIso,
        email: '',
        firstName: '',
        lastName: '',
        jobPosition: '',
        phone: '',
        contractualAgreement: true,
      };
    },
  },
});

export const { setAdminData, clearCompanyData, setCompanyInformation } =
  companyRegistrationSlice.actions;

export default companyRegistrationSlice.reducer;
