import React, { FC } from 'react';
import { ReactComponent as Logo } from 'application/assets/logo-black.svg';
import { LogoWrapper } from './styles';
import ProfileData from '../ProfileData';

const MyProfile: FC = () => {
  return (
    <>
      <LogoWrapper>
        <Logo />
      </LogoWrapper>
      <ProfileData />
    </>
  );
};

export default MyProfile;
