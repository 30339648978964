import React, { FC, useState } from 'react';

import { LanguageDropdown } from 'application/components';
import { useLanguage } from 'application/hooks/useLanguage';
import {
  CompanyDataSessionStorage,
  getCompanyDataFromSessionStorage,
} from 'application/helpers/sessionStorage/companyRegistration';

import { LogoIcon } from './assets';
import {
  LayoutComponent,
  Stepper,
  PersonalInformation,
  CompanyInformation,
  SetPassword,
} from './components';
import { Wrapper, SideBarStyled, MainSection, LanguageSection } from './styles';

const defaultStep =
  getCompanyDataFromSessionStorage(CompanyDataSessionStorage.STEP) || 1;

const CompanyRegistration: FC = () => {
  const { currentLanguage, changeLanguage } = useLanguage();
  const [step, setStep] = useState(Number(defaultStep));

  return (
    <Wrapper>
      <SideBarStyled>
        <MainSection>
          <LogoIcon />
          <Stepper activeStep={step} />
        </MainSection>
        <LanguageSection>
          <LanguageDropdown value={currentLanguage} onChange={changeLanguage} />
        </LanguageSection>
      </SideBarStyled>
      <LayoutComponent step={step}>
        {step === 1 && <PersonalInformation setStep={setStep} />}
        {step === 2 && <CompanyInformation setStep={setStep} />}
        {step === 3 && <SetPassword setStep={setStep} />}
      </LayoutComponent>
    </Wrapper>
  );
};

export default CompanyRegistration;
