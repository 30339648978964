import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { HelpIcon } from 'application/assets';
import { ConfirmModal, LanguageDropdown } from 'application/components';
import { PathNames } from 'application/routes/constants';
import { useLanguage } from 'application/hooks/useLanguage';
import { UsersActions } from 'application/store/reducers/Users/actions';
import { useAppDispatch, useAppSelector } from 'application/hooks/redux';
import { requestSelector } from 'application/store/reducers/request/selectors';
import { isResponseFulfilled } from 'application/helpers/responseHelper';
import { Languages } from 'application/constants/languages';

import { logout } from 'common/auth/authService';

import { LogoutIcon } from './assets';
import { useMenuList } from './hooks/useMenuList';
import { MenuItem, SecondMenu } from './components';
import {
  Wrapper,
  BottomSection,
  LogoutButton,
  DividerStyled,
  Title,
} from './styles';

const AsideMenu: FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { signOut, setUserLanguage } = useAppSelector(requestSelector);
  const { currentLanguage, changeLanguage } = useLanguage();
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const menuItems = useMenuList();

  const isLoading = signOut.loading || setUserLanguage.loading;

  const supportItem = {
    path: PathNames.support,
    name: t('aside_menu.support'),
    image: HelpIcon,
  };

  const handleShowConfirmModal = () => {
    setShowConfirmModal((prev) => !prev);
  };

  const handleChangeLanguage = async (language: Languages) => {
    changeLanguage(language);
    await dispatch(UsersActions.setUserLanguage(language.toLowerCase()));
  };

  const logOut = async () => {
    const response = await dispatch(UsersActions.signOut());
    isResponseFulfilled(response, () => {
      logout();
      handleShowConfirmModal();
    });
  };

  return (
    <>
      <Wrapper>
        <div className={'item-list'}>
          <div>
            {menuItems.map((item) => (
              <MenuItem
                key={item.name}
                path={item.path}
                name={item.name}
                Image={item.image}
                availablePaths={item.availablePaths}
              />
            ))}
          </div>
          <BottomSection>
            <MenuItem
              path={supportItem.path}
              name={supportItem.name}
              Image={supportItem.image}
            />
            <LanguageDropdown
              value={currentLanguage}
              onChange={handleChangeLanguage}
            />
            <DividerStyled />
            <LogoutButton onClick={handleShowConfirmModal}>
              <LogoutIcon />
            </LogoutButton>
          </BottomSection>
        </div>
        {showConfirmModal && (
          <ConfirmModal
            opened={showConfirmModal}
            onClose={handleShowConfirmModal}
            onSubmit={logOut}
            styleType="error"
            isLoading={isLoading}
          >
            <Title>{t('aside_menu.log_out_modal.title')}</Title>
          </ConfirmModal>
        )}
      </Wrapper>
      <SecondMenu />
    </>
  );
};

export default AsideMenu;
