import styled from '@emotion/styled';
import { commonColors } from 'application/theme';

export const StyledTr = styled.tr`
  background: ${commonColors.white};
  > td {
    vertical-align: middle;
    text-align: start;
    padding: 40px 0;
    border-bottom: 1px solid ${commonColors.grey200};
    color: ${commonColors.grey700};
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;

    &:first-of-type {
      border-left: 1px solid ${commonColors.grey200};
    }

    &:last-of-type {
      border-right: 1px solid ${commonColors.grey200};
    }

    > div {
      display: flex;
      align-items: center;
      justify-content: center;

      > svg {
        margin-right: 24px;
      }

      > p {
        color: ${commonColors.grey900};
        font-size: 16px;
        font-weight: 500;
        line-height: 22px;
        > a {
          font-weight: 600;
          color: ${commonColors.blue800};
          text-underline-offset: 2px;
        }
      }
      button {
        all: unset;
        box-sizing: border-box;
        cursor: pointer;
        color: ${commonColors.blue800};
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 140%;
        text-decoration-line: underline;
      }
    }
  }
`;
