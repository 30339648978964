import {
  HrDepartmentAdminConfirmSignUpRequest,
  HrDepartmentAdminConfirmSignupResponse,
  HrDepartmentCompanyCreateRequest,
  HrDepartmentCompanyResponse,
  ResendConfirmationCodeDto,
} from '@docbay/schemas';

import axiosNonTokenInstance from './axiosNonTokenInstance';

export const CompanyRegistrationAPI = {
  companySignUp: (data: HrDepartmentCompanyCreateRequest) => {
    return axiosNonTokenInstance.post<HrDepartmentCompanyResponse>(
      '/hr-department/company/sign-up',
      data
    );
  },
  companyConfirmSingUp: (data: HrDepartmentAdminConfirmSignUpRequest) => {
    return axiosNonTokenInstance.post<HrDepartmentAdminConfirmSignupResponse>(
      '/hr-department/company/confirm-sign-up',
      data
    );
  },
  resendConfirmationCode: (data: ResendConfirmationCodeDto) => {
    return axiosNonTokenInstance.post(
      '/hr-department/company/resend-confirmation-code',
      data
    );
  },
};
