import styled from '@emotion/styled';
import { commonColors } from 'application/theme';

interface StyledButtonProps {
  styleType: 'default' | 'error';
  disabled?: boolean;
}

export const StyledButton = styled.button<StyledButtonProps>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 16px;
  gap: 10px;
  width: 100%;
  height: 48px;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.08px;
  color: ${({ styleType }) =>
    styleType === 'default' ? commonColors.grey900 : commonColors.grey50};
  background-color: ${({ styleType }) =>
    styleType === 'default' ? commonColors.blue300 : commonColors.error2};
  box-shadow: 1px 1px 2px rgba(16, 24, 40, 0.12);
  border: none;
  border-radius: 8px;
  cursor: pointer;

  :hover {
    background-color: ${({ styleType }) =>
      styleType === 'default' ? commonColors.blue400 : commonColors.error2};
  }

  :disabled {
    background-color: ${({ styleType }) =>
      styleType === 'default' ? commonColors.grey300 : commonColors.error2};
    color: ${({ styleType }) =>
      styleType === 'default' ? commonColors.grey600 : commonColors.grey50};
    cursor: not-allowed;
  }
`;
