import React, { FC } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { errorsSelector, Notify } from 'common';
import { PathNames } from './constants';
import { authRouts, publicRouts } from './routes';
import ProtectedRoute from './ProtectedRoute';

export const AppRouter: FC = () => {
  const error = useSelector(errorsSelector);

  return (
    <BrowserRouter>
      <Routes>
        {authRouts.map(({ path, component }) => (
          <Route
            path={path}
            element={<ProtectedRoute>{component}</ProtectedRoute>}
            key={path}
          />
        ))}
        {publicRouts.map(({ path, component }) => (
          <Route path={path} element={component} key={path} />
        ))}
        <Route path="/*" element={<Navigate to={PathNames.login} />} />
      </Routes>
      <Notify message={error?.message || ''} />
    </BrowserRouter>
  );
};
