import styled from '@emotion/styled';
import { commonColors } from 'application/theme';

export const Title = styled.h1`
  color: ${commonColors.grey900};
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  letter-spacing: 0.2px;

  span {
    color: ${commonColors.blue800};
  }
`;
