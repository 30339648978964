import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Input } from 'application/components';

import { FilterSectionProps } from './modules';
import { Wrapper } from './styles';
import { useAppSelector } from 'application/hooks/redux';

const FilterSection: FC<FilterSectionProps> = ({
  searchItem,
  setSearchItem,
}) => {
  const { t } = useTranslation();
  const { total } = useAppSelector((state) => state.locations);

  return (
    <Wrapper>
      <Input
        id={'search'}
        type="search"
        placeholder={t('locations.search_location_placeholder') || ''}
        value={searchItem}
        onChange={(e) => setSearchItem(e.target.value)}
        onClear={() => setSearchItem('')}
        disabled={!total && !searchItem.length}
      />
    </Wrapper>
  );
};

export default FilterSection;
