import { createSlice } from '@reduxjs/toolkit';
import { InitialState } from './models';
import {
  createAdmin,
  createHr,
  deleteAdmin,
  deleteHr,
  editAdmin,
  editHr,
  fetchHrUsers,
} from './actions';

const initialState: InitialState = {
  hrUsers: [],
  hrForAction: null,
  created: false,
  deleted: false,
  edited: false,
  error: '',
  total: 0,
  page: 1,
  totalPages: 0,
};

const hrManagementSlice = createSlice({
  name: 'hrSlice',
  initialState,
  reducers: {
    resetHrForAction: (state) => {
      state.hrForAction = null;
      state.created = false;
      state.edited = false;
      state.deleted = false;
    },
    setHrForAction: (state, action) => {
      state.hrForAction = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createHr.fulfilled, (state, action) => {
      state.created = true;
      state.hrForAction = action.payload;
    });
    builder.addCase(createAdmin.fulfilled, (state, action) => {
      state.created = true;
      state.hrForAction = action.payload;
    });
    builder.addCase(fetchHrUsers.fulfilled, (state, action) => {
      state.hrUsers = action.payload.entities;
      state.page = action.payload.page!;
      state.totalPages = action.payload.totalPages!;
      state.total = action.payload.total!;
    });
    builder.addCase(editHr.fulfilled, (state, action) => {
      state.edited = true;
      state.hrForAction = action.payload;
    });
    builder.addCase(editAdmin.fulfilled, (state, action) => {
      state.edited = true;
      state.hrForAction = action.payload;
    });
    builder.addCase(deleteHr.fulfilled, (state) => {
      state.deleted = true;
    });
    builder.addCase(deleteAdmin.fulfilled, (state) => {
      state.deleted = true;
    });
  },
});

export const { resetHrForAction, setHrForAction } = hrManagementSlice.actions;

export default hrManagementSlice.reducer;
