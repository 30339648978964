import styled from '@emotion/styled';
import { commonColors } from 'application/theme';

export const Wrapper = styled.section`
  display: flex;
  height: 100vh;
  width: 100vw;
`;

export const SideBarStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 32px;
  width: 400px;
  min-width: 400px;
  background-color: ${commonColors.sideMenu};
  height: 100%;
`;

export const MainSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 80px;
  margin-top: 86px;
`;

export const LanguageSection = styled.div`
  width: 100%;
  .language-option-list {
    top: unset;
    bottom: calc(100% + 4px);
  }
`;
