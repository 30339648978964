import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import { ScheduleIcon } from 'application/assets';
import {
  CompanyDataSessionStorage,
  getCompanyDataFromSessionStorage,
} from 'application/helpers/sessionStorage/companyRegistration';

import { TimerProps } from './modules';
import { TimerWrapper } from './styles';

const Timer: FC<TimerProps> = ({
  timeChanged,
  isCodeExpired,
  setIsCodeExpired,
}) => {
  const { t } = useTranslation();
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    const timerEndDateSessionStorage = getCompanyDataFromSessionStorage(
      CompanyDataSessionStorage.TIMER_END_DATE,
    );
    const timerEndDate = timerEndDateSessionStorage
      ? dayjs(timerEndDateSessionStorage)
      : dayjs();
    const minutesDifference = timerEndDate.diff(dayjs(), 'minute');
    const secondsDifference =
      timerEndDate.diff(dayjs(), 'second') - minutesDifference * 60;
    setMinutes(isCodeExpired ? 0 : minutesDifference);
    setSeconds(isCodeExpired ? 0 : secondsDifference);
  }, [isCodeExpired, timeChanged]);

  useEffect(() => {
    if (isCodeExpired) return;

    const myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          setIsCodeExpired(true);
          clearInterval(myInterval);
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  });

  return (
    <TimerWrapper>
      <p>{t('company_registration.set_password.code_still_active')}:</p>
      <ScheduleIcon />
      &nbsp;
      <span>
        {minutes}:{String(seconds).length === 1 ? 0 : ''}
        {seconds} {t('company_registration.set_password.min')}
      </span>
    </TimerWrapper>
  );
};

export default Timer;
