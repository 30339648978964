import styled from '@emotion/styled';
import { commonColors } from 'application/theme';

interface CheckboxError {
  error: boolean;
}

export const ReCaptchaStyled = styled('div')<CheckboxError>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.005em;
    color: ${({ error }) => (error ? commonColors.error2 : '')};
  }
`;
