import React, { FC } from 'react';
import { Modal as ModalMui } from '@mui/material';

import { CloseIcon } from 'application/assets';

import { Loader } from 'common';

import { ModalProps } from './models';
import { Wrapper } from './styles';

const Modal: FC<ModalProps> = ({
  opened,
  onClose,
  children,
  isLoading,
  width,
}) => {
  return (
    <ModalMui open={opened}>
      <>
        {isLoading && <Loader />}
        <Wrapper width={width}>
          <CloseIcon onClick={onClose} className="modal-close-icon" />
          <div>{children}</div>
        </Wrapper>
      </>
    </ModalMui>
  );
};

export default Modal;
