import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HrDepartmentLocationResponseDto } from '@docbay/schemas';

import { useAppDispatch, useAppSelector } from 'application/hooks/redux';
import { Pagination } from 'application/components';
import { requestSelector } from 'application/store/reducers/request/selectors';
import { LocationsActions } from 'application/store/reducers/Locations/actions';
import { isResponseFulfilled } from 'application/helpers/responseHelper';

import EmptyState from '../EmptyState';
import FilterSection from '../FilterSection';
import LocationItem from '../LocationItem';
import DeleteModal from '../DeleteModal';

import { LocationsTableProps } from './models';
import { TableStyled, Wrapper } from './styles';

const LocationsTable: FC<LocationsTableProps> = ({
  searchItem,
  setSearchItem,
  handleShowModal,
  fetchLocations,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { locations, page, total, totalPages } = useAppSelector(
    (state) => state.locations,
  );
  const { getLocations, deleteLocation } = useAppSelector(requestSelector);
  const isLoadingLocations = getLocations.loading;
  const isLoading = deleteLocation.loading;
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showSuccessDeleteModal, setShowSuccessDeleteModal] = useState(false);
  const [locationToDelete, setLocationToDelete] =
    useState<HrDepartmentLocationResponseDto | null>(null);

  const handleChangePage = (currentPage: number) => {
    fetchLocations(currentPage);
  };

  const onEdit = async (location: HrDepartmentLocationResponseDto) => {
    await dispatch(LocationsActions.getLocationById(location.id));
    handleShowModal();
  };

  const onDelete = (location: HrDepartmentLocationResponseDto) => {
    setLocationToDelete(location);
    setShowDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
    setLocationToDelete(null);
  };

  const handleDelete = async () => {
    const response = await dispatch(
      LocationsActions.deleteLocation(locationToDelete?.id!),
    );
    isResponseFulfilled(response, () => {
      setShowDeleteModal(false);
      setShowSuccessDeleteModal(true);
    });
  };

  const onCloseSuccessDeleteModal = () => {
    const isFirstPage = page === 1;
    const currentPage =
      locations.length === 1 && !isFirstPage ? page - 1 : page;

    setLocationToDelete(null);
    setShowSuccessDeleteModal(false);
    fetchLocations(currentPage);
  };

  return (
    <>
      <Wrapper>
        <FilterSection searchItem={searchItem} setSearchItem={setSearchItem} />
        <TableStyled>
          <thead>
            <tr>
              <th>
                <span>{t('locations.table.location_name')}</span>
              </th>
              <th>
                <span>{t('locations.table.linked_employees')}</span>
              </th>
              <th>
                <span>{t('global.actions')}</span>
              </th>
            </tr>
          </thead>
          {!isLoadingLocations && (
            <tbody>
              {locations.length ? (
                locations.map((location) => {
                  return (
                    <LocationItem
                      key={location.id}
                      item={location}
                      onEdit={onEdit}
                      onDelete={onDelete}
                    />
                  );
                })
              ) : (
                <EmptyState
                  handleShowModal={handleShowModal}
                  withSearch={!!searchItem.length}
                />
              )}
            </tbody>
          )}
        </TableStyled>
        {!isLoadingLocations && locations.length ? (
          <Pagination
            pages={totalPages}
            setCurrentPage={handleChangePage}
            currentPage={page}
            itemCount={total}
          />
        ) : (
          ''
        )}
        <DeleteModal
          isLoading={isLoading}
          locationToDelete={locationToDelete}
          showDeleteModal={showDeleteModal}
          handleCloseDeleteModal={handleCloseDeleteModal}
          handleDelete={handleDelete}
          showSuccessDeleteModal={showSuccessDeleteModal}
          onCloseSuccessDeleteModal={onCloseSuccessDeleteModal}
        />
      </Wrapper>
    </>
  );
};

export default LocationsTable;
