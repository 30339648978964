import styled from '@emotion/styled';

import { commonColors } from 'application/theme';

interface DropdownStyledProps {
  isActive: boolean;
}

interface OptionItemProps {
  selected: boolean;
}

export const DropdownStyled = styled.div<DropdownStyledProps>`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 72px;
  position: relative;

  > section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 12px;
    width: 56px;
    height: 20px;
    background: transparent;
    cursor: pointer;

    > div {
      width: 20px;
      height: 20px;
      svg {
        width: 20px;
        height: 20px;
        min-width: 20px;
        min-height: 20px;
      }
    }

    > svg {
      width: 20px;
      height: 20px;
      transform: ${({ isActive }) => (isActive ? 'rotate(180deg)' : 'unset')};
      path {
        fill: ${commonColors.grey200};
      }
    }
  }
`;

export const OptionsList = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 10;
  top: calc(100% + 4px);
  width: 100%;
  border-radius: 8px;
  background: ${commonColors.white};
`;

export const OptionItem = styled.div<OptionItemProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  padding: 0px 16px;
  cursor: pointer;
  background: ${({ selected }) =>
    selected ? commonColors.grey300 : 'transparent'};

  &:hover {
    background: ${commonColors.grey100};
  }

  &:first-of-type {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  &:last-of-type {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  p {
    color: ${commonColors.grey700};
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    letter-spacing: 0.07px;
  }

  svg {
    width: 20px;
    height: 20px;
    min-width: 20px;
    min-height: 20px;
    margin-right: 6px;
  }
`;
