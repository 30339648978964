import { UserPermissions } from 'application/constants/userPermissions';
import { getProfilePermissions } from './sessionStorage/auth';

export const featuresPermissions = () => {
  const profilePermissions = getProfilePermissions();

  const checkPermission = (feature: UserPermissions) => {
    if (!profilePermissions?.length) return true;
    return profilePermissions.includes(feature);
  };

  const hasSubscriptionManagementPermission = checkPermission(
    UserPermissions.subscriptionManagement,
  );
  const hasDepartmentManagementPermission = checkPermission(
    UserPermissions.departmentManagement,
  );
  const hasCreditManagementPermission = checkPermission(
    UserPermissions.creditManagement,
  );
  const hasCompanyManagementPermission = checkPermission(
    UserPermissions.companyManagement,
  );
  const hasAdminManagementPermission = checkPermission(
    UserPermissions.adminManagement,
  );
  const hasHrManagementPermission = checkPermission(
    UserPermissions.hrManagement,
  );
  const hasLocationManagementPermission = checkPermission(
    UserPermissions.locationManagement,
  );
  const hasEmployeeManagementPermission = checkPermission(
    UserPermissions.employeeManagement,
  );

  return {
    hasSubscriptionManagementPermission,
    hasDepartmentManagementPermission,
    hasCreditManagementPermission,
    hasCompanyManagementPermission,
    hasAdminManagementPermission,
    hasHrManagementPermission,
    hasLocationManagementPermission,
    hasEmployeeManagementPermission,
  };
};
