import * as yup from 'yup';
import { useTranslation } from 'react-i18next';

export const useCompanyInfoSchema = () => {
  const { t } = useTranslation();
  const requiredError = t('errors.required') || '';
  const lengthError = t('errors.at_least_2_characters');

  return yup.object({
    name: yup.string().min(2, lengthError).required(requiredError),
    address: yup.string().min(2, lengthError).required(requiredError),
    taxId: yup.string().min(2, lengthError).required(requiredError),
  });
};
