import styled from '@emotion/styled';
import { commonColors } from 'application/theme';

interface StyledProps {
  isError?: boolean;
  disabled?: boolean;
  type?: string;
}

export const StyledInput = styled.div<StyledProps>`
  display: grid;
  grid-auto-rows: max-content;
  gap: 6px;

  label {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    letter-spacing: 0.005em;
    color: ${({ isError, disabled }) =>
      isError
        ? commonColors.error2
        : commonColors[disabled ? 'grey400' : 'grey600']};
  }

  span {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.005em;
    color: ${({ isError, disabled }) =>
      isError
        ? commonColors.error2
        : commonColors[disabled ? 'grey400' : 'grey600']};
  }

  input {
    padding: 10px 14px;
    width: 100%;
    height: 44px;
    background: ${({ isError }) =>
      isError ? commonColors.error3 : commonColors.white};
    border: 1px solid
      ${({ isError }) => (isError ? commonColors.error2 : commonColors.grey300)};
    box-shadow: 1px 1px 2px rgba(16, 24, 40, 0.12);
    border-radius: 8px;
    outline: none;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.005em;
    color: ${({ disabled }) =>
      disabled ? commonColors.grey500 : commonColors.grey700};
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    :focus {
      background: ${({ isError }) =>
        isError ? commonColors.error3 : commonColors.blue50};
      border: 1px solid
        ${({ isError }) =>
          isError ? commonColors.error2 : commonColors.blue800};

      span {
        border-right: 1px solid ${({ theme }) => commonColors.blue300};
      }
    }

    ::placeholder {
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
      letter-spacing: 0.005em;
      color: ${({ disabled }) =>
        disabled ? commonColors.grey300 : commonColors.grey500};
    }
    :disabled {
      background: ${commonColors.grey50};
      border: 1px solid ${commonColors.grey300};
    }
  }

  input[type='email'] {
    padding-left: 42px;
    padding-right: ${({ isError }) => (isError ? '42px' : '14px')};
  }

  input[type='search'] {
    padding: 0 42px;
  }

  input[type='search']::-webkit-search-cancel-button {
    display: none;
  }

  input[type='password'] {
    padding-right: 40px;
  }
`;

export const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  svg {
    margin-left: 8px;
    width: 16px;
    height: 16px;

    path {
      fill: ${commonColors.error2};
    }
  }
`;

export const InputWrapper = styled.div<StyledProps>`
  position: relative;
  > svg {
    width: 20px;
    height: 20px;
    position: absolute;
    top: 50%;
    left: 16px;
    transform: translate(0, -50%);
    path {
      fill: ${({ disabled }) =>
        disabled ? commonColors.grey300 : commonColors.grey500};
    }

    &:last-child {
      left: ${({ type }) => (type === 'search' ? 'unset' : '16px')};
      right: ${({ type }) => (type === 'search' ? '14px' : 'unset')};
      cursor: ${({ type }) => (type === 'search' ? 'pointer' : 'auto')};
    }
  }

  span {
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translate(0, -50%);
    svg {
      margin-top: 4px;
      width: 17px;
      height: 17px;
      path {
        fill: ${commonColors.error2};
      }
    }
  }

  button {
    position: absolute;
    right: 0;
    cursor: pointer;
    width: 44px;
    height: 44px;
    border: none;
    background: none;
    svg {
      margin-top: 2px;
      path {
        fill: ${({ disabled }) =>
          disabled ? commonColors.grey300 : commonColors.grey500};
      }
    }
  }
`;
