import { AxiosError } from 'axios';
import { PayloadAction } from '@reduxjs/toolkit';

import { ResponseDto } from 'integration/api/models';

export const isResponseFulfilled = (
  response: { type: string },
  callBack?: () => void | Promise<void>,
  callBackError?: () => void | Promise<void>,
) => {
  if (response.type.endsWith('/fulfilled')) {
    callBack && callBack();
  } else {
    callBackError && callBackError();
  }
};

export const getResponseError = (
  action: PayloadAction<AxiosError<ResponseDto>>,
) => {
  const error = action.payload;
  const errorCode = error?.response && error?.response.data.errorCode;

  return errorCode;
};
