import styled from '@emotion/styled';
import { commonColors } from 'application/theme';
import { Link } from 'react-router-dom';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding: 32px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: inherit;
  gap: 28px;
  margin-bottom: 48px;
`;

export const DividerStyled = styled.div`
  height: 1px;
  width: 100%;
  border-top: 1px solid ${commonColors.grey200};
`;

export const HeaderStyled = styled.header`
  display: flex;
  gap: 40px;
  width: 100%;

  > div:first-of-type {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;

    > h1 {
      color: ${commonColors.grey900};
      font-size: 28px;
      font-style: normal;
      font-weight: 700;
      line-height: 140%;
      letter-spacing: 0.28px;
      text-transform: capitalize;
    }

    > p {
      color: ${commonColors.grey700};
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%;
      letter-spacing: 0.09px;
    }

    a {
      color: ${commonColors.blue800};
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 140%;
      letter-spacing: 0.09px;
      text-decoration-line: underline;
    }
  }

  > div:last-of-type {
    button {
      width: 110px;
      height: 40px;
    }
  }
`;

export const FooterStyled = styled.footer`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  p {
    color: ${commonColors.grey600};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: 0.07px;
  }

  > div {
    display: flex;
    align-items: center;
    gap: 12px;

    button {
      all: unset;
      box-sizing: border-box;
      cursor: pointer;

      color: ${commonColors.grey600};
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
      letter-spacing: 0.07px;
    }
  }
`;

export const DividerVertical = styled.div`
  width: 1px;
  height: 11px;
  background-color: ${commonColors.grey400};
`;

export const LinkStyled = styled(Link)`
  text-decoration: none;
  text-decoration-line: none;
  button {
    width: 110px;
    height: 40px;

    svg {
      width: 20px;
      height: 20px;
      min-width: 20px;
      min-height: 20px;
      path {
        fill: ${commonColors.blue800};
      }
    }
    :disabled {
      svg {
        path {
          fill: ${commonColors.blue200};
        }
      }
    }
  }
`;
