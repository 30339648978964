import { createAsyncThunk } from '@reduxjs/toolkit';
import { RequestEnum } from 'application/store/reducers/request/model';
import { DepartmentAPI } from 'integration/api/department';

interface Props {
  page?: number;
  limit?: number;
  languageIso?: string;
  search?: string;
}

export const getDepartmentsByCompany = createAsyncThunk(
  RequestEnum.getDepartmentsByCompany,
  async (params: Props | undefined, { rejectWithValue }) => {
    try {
      const { data } = await DepartmentAPI.getDepartmentsByCompany(params);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

export const createDepartment = createAsyncThunk(
  RequestEnum.createDepartment,
  async (name: string, { rejectWithValue }) => {
    try {
      const { data } = await DepartmentAPI.createDepartment(name);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

export const deleteDepartment = createAsyncThunk(
  RequestEnum.deleteDepartment,
  async (
    params: { departmentForDelete: string; isForceDelete?: boolean },
    { rejectWithValue },
  ) => {
    try {
      const { data } = await DepartmentAPI.deleteDepartment(params);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

export const editDepartment = createAsyncThunk(
  RequestEnum.editDepartment,
  async (
    params: { name: string; departmentId?: string },
    { rejectWithValue },
  ) => {
    try {
      const { data } = await DepartmentAPI.editDepartment(params);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);
