import React, { FC, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import { Input, PrimaryButton, SecondaryButton } from 'application/components';
import { useAppDispatch, useAppSelector } from 'application/hooks/redux';
import { CompanyRegistrationActions } from 'application/store/reducers/CompanyRegistration/actions';
import {
  getResponseError,
  isResponseFulfilled,
} from 'application/helpers/responseHelper';
import {
  CompanyDataSessionStorage,
  setCompanyDataToSessionStorage,
  getCompanyDataFromSessionStorage,
} from 'application/helpers/sessionStorage/companyRegistration';
import { requestSelector } from 'application/store/reducers/request/selectors';
import { setCompanyInformation } from 'application/store/reducers/CompanyRegistration/companyRegistrationSlice';

import { ERROR_CODE, Loader } from 'common';

import { ResponseDto } from 'integration/api/models';

import { useCompanyInfoSchema } from 'features/company-registration/hooks/useCompanyInfoSchema';

import ButtonGroup from '../ButtonGroup';

import { FormData, CompanyInformationProps } from './models';
import { StyledForm } from './styles';

const CompanyInformation: FC<CompanyInformationProps> = ({ setStep }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { name, address, taxId, adminData } = useAppSelector(
    (state) => state.companyRegistration,
  );
  const { loading } = useAppSelector(requestSelector).companySignUp;

  const isLoading = loading;

  const companyInfoSchema = useCompanyInfoSchema();

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver(companyInfoSchema),
    mode: 'onChange',
  });

  useEffect(() => {
    setValue('name', name);
    setValue('address', address);
    setValue('taxId', taxId);
  }, [name, address, taxId]);

  useEffect(() => {
    dispatch(setCompanyInformation(watch()));
  }, [watch()]);

  const errorHandler = (action: PayloadAction<AxiosError<ResponseDto>>) => {
    const errorCode = getResponseError(action);
    const isEmailError =
      errorCode === ERROR_CODE.USER_WITH_EMAIL_ALREADY_EXISTS;
    const isPhoneError = errorCode === ERROR_CODE.HR_USER_EXISTS_BY_PHONE;

    if (isEmailError) {
      setCompanyDataToSessionStorage(
        CompanyDataSessionStorage.HAS_EMAIL_ERROR,
        true,
      );
      setCompanyDataToSessionStorage(
        CompanyDataSessionStorage.EMAIL_OLD,
        adminData.email,
      );
      setStep(1);
    }
    if (isPhoneError) {
      setCompanyDataToSessionStorage(
        CompanyDataSessionStorage.HAS_PHONE_ERROR,
        true,
      );
      setCompanyDataToSessionStorage(
        CompanyDataSessionStorage.PHONE_OLD,
        adminData.phone,
      );
      setStep(1);
    }
  };

  const goToNextStep = () => {
    setCompanyDataToSessionStorage(CompanyDataSessionStorage.STEP, 3);
    setCompanyDataToSessionStorage(
      CompanyDataSessionStorage.COMPANY_INFORMATION,
      watch(),
    );
    setStep(3);
  };

  const onSubmit: SubmitHandler<FormData> = async (data) => {
    const isUserCreated =
      adminData.email ===
      getCompanyDataFromSessionStorage(CompanyDataSessionStorage.USER_EMAIL);

    if (isUserCreated) {
      goToNextStep();
    } else {
      const response = await dispatch(
        CompanyRegistrationActions.companySignUp({
          ...data,
          adminData: { ...adminData, phone: `+${adminData.phone}` },
        }),
      );
      isResponseFulfilled(
        response,
        () => {
          const timerCountDownInMinutes = 5;
          setCompanyDataToSessionStorage(
            CompanyDataSessionStorage.TIMER_END_DATE,
            dayjs().set(
              'minute',
              dayjs().get('minute') + timerCountDownInMinutes,
            ),
          );
          goToNextStep();
        },
        () => {
          errorHandler(response as PayloadAction<AxiosError<ResponseDto>>);
        },
      );
    }
  };

  const onBack = () => {
    setStep(1);
    setCompanyDataToSessionStorage(CompanyDataSessionStorage.STEP, 1);
  };

  const disabledButton = !!Object.keys(errors).length;

  return (
    <>
      {isLoading && <Loader />}
      <StyledForm onSubmit={handleSubmit(onSubmit)}>
        <div>
          <Input
            id="name"
            label={t('global.company_name') || ''}
            type="text"
            placeholder={t('global.company_name_placeholder') || ''}
            errorMessage={errors.name?.message}
            register={register}
          />
          <Input
            id="address"
            label={t('global.company_address') || ''}
            type="text"
            placeholder={t('global.company_address_placeholder') || ''}
            errorMessage={errors.address?.message}
            register={register}
          />
          <Input
            id="taxId"
            label={t('global.tax_id') || ''}
            type="text"
            placeholder={t('global.tax_id_placeholder') || ''}
            errorMessage={errors.taxId?.message}
            register={register}
          />
        </div>
        <ButtonGroup>
          <PrimaryButton disabled={disabledButton} type="submit">
            {t('global.continue')}
          </PrimaryButton>
          <SecondaryButton onClick={onBack} disabled={false} type="button">
            {t('global.back')}
          </SecondaryButton>
        </ButtonGroup>
      </StyledForm>
    </>
  );
};
export default CompanyInformation;
