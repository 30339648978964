import {
  HrDepartmentAdminConfirmSignUpRequest,
  HrDepartmentCompanyCreateRequest,
  ResendConfirmationCodeDto,
} from '@docbay/schemas';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { RequestEnum } from 'application/store/reducers/request/model';

import { CompanyRegistrationAPI } from 'integration/api/companyRegistration';

const companySignUp = createAsyncThunk(
  RequestEnum.companySignUp,
  async (params: HrDepartmentCompanyCreateRequest, { rejectWithValue }) => {
    try {
      const { data } = await CompanyRegistrationAPI.companySignUp(params);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

const companyConfirmSingUp = createAsyncThunk(
  RequestEnum.companyConfirmSingUp,
  async (
    params: HrDepartmentAdminConfirmSignUpRequest,
    { rejectWithValue },
  ) => {
    try {
      const { data } = await CompanyRegistrationAPI.companyConfirmSingUp(
        params,
      );
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

const resendConfirmationCode = createAsyncThunk(
  RequestEnum.resendConfirmationCode,
  async (params: ResendConfirmationCodeDto, { rejectWithValue }) => {
    try {
      const { data } = await CompanyRegistrationAPI.resendConfirmationCode(
        params,
      );
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

export const CompanyRegistrationActions = {
  companySignUp,
  companyConfirmSingUp,
  resendConfirmationCode,
};
