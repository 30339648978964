import styled from '@emotion/styled';
import { commonColors } from 'application/theme';

interface StyledProps {
  showMenu: boolean;
}

export const Wrapper = styled.div<StyledProps>`
  display: ${({ showMenu }) => (showMenu ? 'flex' : 'none')};
  flex-direction: column;
  gap: 16px;
  padding: 0 8px;
  min-width: 260px;
  width: 260px;
  max-height: 100vh;
  background: ${commonColors.grey100};
`;
