import styled from '@emotion/styled';
import { commonColors } from 'application/theme';

export const Wrapper = styled.aside`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;

  width: 94px;
  height: 100vh;

  background: ${commonColors.menu};
  transition: width 0.3s ease-in-out;
  padding: 24px 0 32px;

  &::-webkit-scrollbar {
    display: none;
  }

  .item-list {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    margin-top: 8px;
  }
`;

export const ButtonStyled = styled.button`
  all: unset;
  cursor: pointer;
  margin-bottom: 24px;
  svg {
    width: 44px;
    height: 44px;
  }

  &:hover {
    svg {
      path {
        stroke: ${commonColors.blue300};
      }
    }
  }
`;

export const BottomSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
`;

export const LogoutButton = styled.button`
  all: unset;
  box-sizing: border-box;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;

  svg {
    width: 24px;
    height: 24px;
  }
`;

export const DividerStyled = styled.div`
  width: 100%;
  border-top: 1px solid ${commonColors.grey600};
`;

export const Title = styled.h1`
  color: ${commonColors.grey900};
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  letter-spacing: 0.2px;
`;
