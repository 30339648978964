import React, { FC, FormEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { Modal, SecondaryButton, PrimaryButton } from 'application/components';

import { ConfirmModalProps } from './models';
import { FormStyled, ButtonsSection } from './styles';

const ConfirmModal: FC<ConfirmModalProps> = ({
  opened,
  onClose,
  onSubmit,
  children,
  styleType = 'default',
  isLoading,
}) => {
  const { t } = useTranslation();
  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onSubmit(event);
  };

  return (
    <Modal opened={opened} onClose={onClose} isLoading={isLoading}>
      <FormStyled onSubmit={handleSubmit}>
        {children}
        <ButtonsSection>
          <SecondaryButton type="button" onClick={onClose}>
            {t('global.cancel')}
          </SecondaryButton>
          <PrimaryButton type="submit" styleType={styleType}>
            {t('global.confirm')}
          </PrimaryButton>
        </ButtonsSection>
      </FormStyled>
    </Modal>
  );
};

export default ConfirmModal;
