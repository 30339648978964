import React, { FC } from 'react';

import { MaskIcon, CheckedIcon } from 'features/company-registration/assets';
import { useSteps } from 'features/company-registration/hooks/useSteps';

import { StepperProps } from './models';
import { Wrapper, StepStyled } from './styles';

const Stepper: FC<StepperProps> = ({ activeStep }) => {
  const steps = useSteps();

  return (
    <Wrapper>
      {steps.map((step, index) => (
        <StepStyled
          key={step.id}
          active={activeStep === step.id}
          completed={activeStep > step.id}
        >
          <div>
            <div>{activeStep > step.id ? <CheckedIcon /> : step.id}</div>
            <p>{step.label}</p>
          </div>
          {steps.length !== index + 1 && <MaskIcon />}
        </StepStyled>
      ))}
    </Wrapper>
  );
};

export default Stepper;
