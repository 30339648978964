import React, { FC, useMemo } from 'react';
import { ProfileDataStyled, ProfessionalInfo } from './styles';
import { useTranslation } from 'react-i18next';
import { UserRoles } from 'application/constants/userRoles';
import { getProfilesFromSessionStorage } from 'application/helpers/sessionStorage/auth';

const ProfileData: FC = () => {
  const { t, i18n } = useTranslation();

  const currentProfile = useMemo(() => {
    const profiles = getProfilesFromSessionStorage();

    const currentProfile = profiles?.length ? profiles[0] : null;

    return {
      id: currentProfile?.id,
      firstName: currentProfile?.firstName,
      lastName: currentProfile?.lastName,
      userTypes: currentProfile?.userTypes,
    };
  }, []);

  const currentProfileInitials = currentProfile?.firstName
    ? `${currentProfile?.firstName!.slice(
        0,
        1,
      )}${currentProfile?.lastName!.slice(0, 1)}`
    : '';

  const formattedUserRoles = useMemo(() => {
    return currentProfile?.userTypes?.includes(UserRoles.HrDepartmentAdmin)
      ? t('user_types.admin')
      : t('user_types.hr');
  }, [t, i18n.language, currentProfile]);

  return (
    <ProfileDataStyled>
      <div className="accordion-root">
        <div className="accordion-info">
          <div className="image">
            <>{currentProfileInitials}</>
          </div>
          <ProfessionalInfo>
            <p>{`${currentProfile?.firstName} ${currentProfile?.lastName}`}</p>
            <span>{formattedUserRoles}</span>
          </ProfessionalInfo>
        </div>
      </div>
    </ProfileDataStyled>
  );
};
export default ProfileData;
