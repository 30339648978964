export enum UserPermissions {
  subscriptionManagement = 'SubscriptionManagement',
  departmentManagement = 'DepartmentManagement',
  creditManagement = 'CreditManagement',
  companyManagement = 'CompanyManagement',
  adminManagement = 'AdminManagement',
  hrManagement = 'HrManagement',
  locationManagement = 'LocationManagement',
  employeeManagement = 'EmployeeManagement',
}
