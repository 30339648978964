import styled from '@emotion/styled';

export const StyledForm = styled.form`
  height: inherit;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  > div:first-of-type {
    display: grid;
    gap: 16px;
    max-width: 470px;
    margin-bottom: 20px;
  }

  button {
    height: 48px;
  }
`;
