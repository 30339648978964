import { useTranslation } from 'react-i18next';

import { PathNames } from 'application/routes/constants';

import {
  DashboardIcon,
  FolderSharedIcon,
  SettingsIcon,
  RequestQuoteIcon,
} from '../assets';

export const useMenuList = () => {
  const { t } = useTranslation();

  const menuList = [
    {
      path: PathNames.dashboard,
      name: t('aside_menu.dashboard'),
      image: DashboardIcon,
      availablePaths: [],
    },
    {
      path: PathNames.hrManagement,
      name: t('aside_menu.staff'),
      image: FolderSharedIcon,
      availablePaths: [PathNames.hrManagement, PathNames.employeesManagement],
    },
    {
      path: PathNames.departments,
      name: t('aside_menu.settings'),
      image: SettingsIcon,
      availablePaths: [PathNames.departments, PathNames.locations],
    },
    {
      path: PathNames.quotes,
      name: t('aside_menu.quotes'),
      image: RequestQuoteIcon,
      availablePaths: [],
    },
  ];

  return menuList;
};
