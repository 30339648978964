import { useTranslation } from 'react-i18next';

export const useSteps = () => {
  const { t } = useTranslation();

  const steps = [
    {
      id: 1,
      label: t('company_registration.header.title_1'),
    },
    {
      id: 2,
      label: t('company_registration.header.title_2'),
    },
    {
      id: 3,
      label: t('company_registration.header.title_3'),
    },
  ];

  return steps;
};
