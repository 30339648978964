import styled from '@emotion/styled';
import { commonColors } from 'application/theme';

export const Wrapper = styled.main`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100vh;

  .mr-120 {
    margin-top: 120px;
  }
`;

export const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 32px;
  height: 60px;
  min-height: 60px;
  background: ${commonColors.sideMenu};
`;

export const Container = styled.div`
  height: inherit;
  display: flex;
  align-items: self-start;
  justify-content: center;
`;

export const Footer = styled.footer`
  display: flex;
  align-items: center;
  padding: 6px 32px;
  background: ${commonColors.sideMenu};

  p {
    color: ${commonColors.white};
    text-align: right;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
  }
`;
