import styled from '@emotion/styled';
import { commonColors } from 'application/theme';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

interface StyledProps {
  isActive: boolean;
  isError?: boolean;
}

interface ErrorProps {
  isError?: boolean;
}

export const Wrapper = styled.div<ErrorProps>`
  display: grid;
  grid-auto-rows: max-content;
  gap: 6px;
  width: 100%;

  > label {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.08px;
    color: ${({ isError }) =>
      isError ? commonColors.error2 : commonColors.grey600};
  }

  > span {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.07px;
    color: ${commonColors.error2};
  }
`;

export const DatePickerStyled = styled(DatePicker)<StyledProps>`
  width: 100%;
  .MuiInputBase-root,
  .MuiOutlinedInput-root {
    height: 44px;
    width: 100%;
    background: ${({ isActive, isError }) =>
      isError
        ? commonColors.error3
        : isActive
        ? commonColors.blue50
        : commonColors.white};
    border: 1px solid
      ${({ isActive, isError }) =>
        isError
          ? commonColors.error2
          : isActive
          ? commonColors.blue800
          : commonColors.grey300};
    box-shadow: 1px 1px 2px rgba(16, 24, 40, 0.12);
    border-radius: 8px;
    outline: none;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.005em;
    color: ${commonColors.grey700};

    > input {
      padding: 10px 14px;
    }
  }

  .MuiOutlinedInput-notchedOutline {
    border: none;
  }
`;
