import styled from '@emotion/styled';

export const StyledMain = styled.div`
  display: flex;
  position: fixed;
  height: 100vh;
  width: 100%;
  
  > div:last-child {
      display: flex;
      flex: 1 1 0%;
      flex-direction: column;
      padding: 0 32px 20px;
      background: rgb(249, 250, 251);
      height: 100%;
      width: 100%;
      overflow-y: auto;
  }
`