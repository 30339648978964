import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { SecondaryButton } from 'application/components';
import { HelpIcon } from 'application/assets/index';
import { HeaderStyled } from './styles';
import { Link } from 'react-router-dom';
import { DocBaySupportLink } from 'application/constants/externalLinks';

const Header: FC = () => {
  const { t } = useTranslation();

  return (
    <HeaderStyled>
      <div>
        <h1>{t('header.need_help')}</h1>
      </div>
      <Link to={DocBaySupportLink} target="_blank">
        <SecondaryButton styleType={'color'} type="button">
          <HelpIcon />
          {t('header.help')}
        </SecondaryButton>
      </Link>
    </HeaderStyled>
  );
};

export default Header;
